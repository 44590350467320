@font-face {
  font-family: 'Poppins';
  src: local('Poppins'),
    url('../assets/Fonts/Poppins-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins-Medium';
  src: local('Poppins-Medium'),
    url('../assets/Fonts/Poppins-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins-SemiBold';
  src: local('Poppins-Medium'),
    url('../assets/Fonts/Poppins-SemiBold.ttf') format('truetype');
}

/* input,
textarea {
  direction: ltr !important;
} */

/* [dir="ltr"] img {
  transform: scaleX(1); 
}

[dir="rtl"] img {
  transform: scaleX(-1);
} */
