.location-landing-page-main-container {
    width: 100%;
    height: 1400px;
    position: relative;
    overflow-y: auto;
    background-color:  #F9F9F8 !important;
}

@media screen and (min-width: 768px) {
  .location-landing-page-main-container {
    height: 2000px;
  }
}


.user-location-landing-page-header {
    max-width: 100%;
    overflow: hidden;
    display: flex;
    max-height: 140px;
    flex-direction: row;
    padding: 60px 100px 12px 100px;
    background-color: #ffffff;
}

.user-location-header-logo-container {
    display: flex;
    align-items: center;
    height: max-content;
    width: 56%;
}

.root-page {
    padding: 60px 100px 32px 150px;
    background-color: #ffffff;
    min-width: 275;
    font-family: "Poppins-Medium", sans-serif !important;
}

.header-item-text {
    font-family: "Poppins-Medium", sans-serif !important;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #2c2c2e;
    cursor: pointer;
    margin-left: 40px;
}

@media screen and (min-width: 768px) {
    .header-item-text {
        font-size: 24px;
        line-height: 27px;
        margin-left: 40px;
    }
    .businesssetup-header-item-text{
        font-size: 24px;
        line-height: 27px;
    }
}
.businesssetup-header-item-text{
    font-family: "Poppins-Medium", sans-serif !important;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #2c2c2e;
    cursor: pointer;
}


.location-note-item-text {
    font-family: "Poppins-Medium", sans-serif !important;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #2c2c2e;
    cursor: pointer;
}

@media (max-width: 768px) {
    .location-note-item-text {
        font-size: 14px;
        line-height: 20px;
        text-indent: 20px;
    }
}

@media (max-width: 480px) {
    .location-note-item-text {
        font-size: 12px;
        line-height: 18px;
        text-indent: 10px;
    }
}


.form-input-container {
    display: flex;
    /* padding: 10px; */
    /* flex-direction: column; */
}

.sub-header-item-text {
    font-family: "Poppins-Medium", sans-serif !important;
    font-style: normal;
    font-weight: 500;
    font-size: 19px;
    line-height: 27px;
    color: #2c2c2e;
    cursor: pointer;
    /* margin-bottom: 15px; */
}

.employees-team-size {
    position: absolute;
    display: flex;
    padding: 60px 100px 32px 20px;
}

.location-main-container-text {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    position: absolute;
  }
  
  /* Media queries for responsiveness */
  @media (max-width: 768px) {
    .location-main-container-text {
      position: relative; /* Change the positioning to relative */
      justify-content: flex-start; /* Adjust the horizontal alignment for smaller devices */
      padding: 10px; /* Add padding to create spacing for smaller devices */
    }
  }
  
  @media (max-width: 480px) {
    .location-main-container-text {
      padding: 5px; /* Adjust the padding for even smaller devices */
    }
  }
  


.container-text {
    font-family: "Poppins-Medium", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    margin-top: 50px;
    margin-left: 60px;
}

.location-papar-container {
    display: flex;
    width: 100%;
    height: auto;
    margin-top: 20px;
}

@media screen and (min-width: 768px) {
    .location-papar-container {
        width: 650px !important;
        height: 900px !important;
        left: 120px;
        top: 293px;
        margin-top: 40px;
    }
}

.input-container {
    display: flex;
    margin-top: 20px;
    margin-left: 10px;
    margin-right: 10px;
    flex-direction: column;
}

.input-container-bussiness-setup {
    margin-top: 20px;
}

@media screen and (min-width: 768px) {
    .input-container {
        /* position: absolute; */
        margin-top: 40px;
        margin-left: 40px;
        margin-right: 40px;
        flex-direction: row;
    }
}

.location-form-label {
    font-family: "Poppins-Medium", sans-serif;
    /* margin-right: 305px; */
    display: flex;
    /* margin-right: 10px; */
    color: #2C2C2E;
}

@media screen and (max-width: 768px) {
    .location-form-label {
        /* margin-right: 305px; */
        margin-bottom: 3px;
        display: flex;
        margin-right: 40px;
    }
}
.location-form-input {
    margin-top: 10px;
    width: 536px;
    height: 48px;
    border-radius: 8px !important;
    margin-right: 40px;
    /* border: 1px solid #757575; */
    font-family: "Poppins-Medium", sans-serif !important;
}

.location-form-input-bussiness-setup {
    width: 100%;
    margin-top: 10px;
    height: 48px;
    border-radius: 8px !important;
    font-family: "Poppins-Medium", sans-serif !important;
}

@media (max-width: 768px) {
    .location-form-input {
        width: 100%;
        max-width: 536px;
        margin-right: 0;
        margin-top: 30px !important;
    }
}


.location-form-input-style {
    font-family: "Poppins-Medium", sans-serif !important;
    display: flex;
    width: 536px;
    height: 48px;
    margin-top: 10px !important;
    margin-right: 40px;
}

.location-form-input-style-bussiness-setup {
    font-family: "Poppins-Medium", sans-serif !important;
    height: 48px;
    margin-top: 10px !important;
}

@media only screen and (max-width: 768px) {
  .location-form-input-style {
    width: 100%;
    margin-right: 0;
    margin-top: 30px !important;
    margin-bottom: 10px;
  }
}


.employe-team-container {
    display: flex;
    flex-direction: column;
}

.employe-team-label {
    flex-direction: column;
}


.employe-team-header {
    display: flex;
    font-family: "Poppins-Medium", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    color: #48484a;
    flex-direction: column;
}

@media (max-width: 767px) {
    .employe-team-header {
        font-size: 20px;
        line-height: 28px;
    }
}


.employe-team-subtext {
    display: flex;
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #48484a;
    flex-direction: column;
}

@media screen and (max-width: 768px) {
    .employe-team-subtext {
        font-size: 14px;
        line-height: 20px;
    }
}


.employe-team-checkbox {
    display: flex;
    flex-direction: column;
  }
  
  /* Responsive Styles */
  
  
.terms-checkbox-input{
    font-style: "normal";
    font-weight: 500;
    font-Size: "20px";
    line-height: "30px";
    font-family: "Poppins-Medium", sans-serif;
}


.business-type-label {
    font-family: "Poppins-Medium", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    color: #48484a;
}
@media (max-width: 768px) {
    .business-type-label {
        margin: 10px 0;
        font-size: 20px;
        text-align: center;
    }
}



.business-type-container {
    margin-top: 32px;
    margin-bottom: 20px !important;
    display: flex;
    flex-wrap: wrap;
}

@media (max-width: 768px) {
    .business-type-container {
        flex-direction: column;
        margin-top: 10px !important;
        margin-bottom: 20px !important;
    }
}


.business-type-error-msg{
    margin-top: 10px !important;
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    position: relative;
    
}

.business-types {
    display: flex;
    flex-direction: row;
}
.business-button{
    border-radius: 16px !important;
    background-color: #3D40C3 !important;
    text-transform: none !important;
    white-space: nowrap !important
}

.business-type-button {
    font-family: 'Poppins-Medium', sans-serif !important;
    width: 109px;
    height: 28px;
    padding-left: 440px;
    text-transform: none;
}

@media (max-width: 768px) {
    .business-type-button {
        width: 100%;
        padding: 10px;
        margin-left: 40px !important;
    }
}

.business-type-options {
    display: flex;
    flex-direction: row;
    position: relative;
}

.business-type-icon {
    margin-top: 10px;
    border-radius: 24px;
    cursor: pointer;
    flex-direction: row;
}

.next-Step {
    margin-left: 60px;
    margin-top: 30px;
    margin-bottom: 40px !important;
    display: flex;
}

.next-Step-location {
    margin-top: 30px !important;
    margin-bottom: 40px !important;
    display: flex;
    justify-content: center;
    width: 100%;
    margin: auto;
}

.next-Step-button {
    width: 418px !important;
    height: 50px !important;
    background-color: #1e5fea !important;
    color: #ffffff !important;
    border-radius: 25px !important;
    font-family: "Poppins-Medium", sans-serif !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 18px !important;
    text-align: center !important;
    text-transform: none !important;
}

@media (max-width: 768px) {
    .next-Step-button {
        width: 90% !important;
        margin: 0 auto;
    }
}

.location-error-message-text {
    font-family: "Poppins-Medium", sans-serif !important;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #FF453A;
    margin-top: 12px;
}

.location-team-size-card {
    width: 100%;
    background: #FFFFFF !important;
    border-radius: 16px !important;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .location-team-size-card {
      margin-bottom: 20px !important;
      width: 100%;
    }
  }
  