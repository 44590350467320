.manage-booking-reschdule-calendar {
  background: #ffffff;
  margin-top: 2% !important;
  max-width: 100% !important;

  .manage-booking-reschdule-calendar-sub-div {
    text-align: -webkit-center;
    text-align: -moz-center;

    .react-calendar {
      width: 550px;
      border-radius: 24px;
      background: #ffffff;
      background-blend-mode: normal;
      border: none !important;

      .react-calendar__navigation:hover {
        background: #ffffff;
      }

      .react-calendar__navigation {
        width: 260px !important;
        margin-top: 3%;
        padding-bottom: 34px;
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: center;

        .react-calendar__navigation__label {
          height: 46px;
          color: #2c2c2e;
          font-family: "Poppins-Medium", sans-serif;
          font-size: 24px;
          font-weight: 600;
          line-height: 40px;
          flex-grow: 0 !important;

          // span {
          //   padding-left: 20px;
          //   padding-right: 20px;
          // }
        }

        button:disabled {
          background-color: #ffffff !important;
        }

        .react-calendar__navigation__prev2-button {
          display: none;
        }

        .react-calendar__navigation__next2-button {
          display: none;
        }
      }

      .react-calendar__viewContainer {
        .react-calendar__month-view {
          padding-left: 20px;
          padding-right: 20px;

          .react-calendar__month-view__weekdays {
            abbr {
              text-decoration: none !important;
              color: #48484a;
              font-size: 16px;
              font-weight: 400;
              line-height: 14px;
              text-align: center;
              font-family: "Poppins", sans-serif;
              text-transform: uppercase;

              @media (max-width: 590px) {
                font-size: 12px;
              }
            }
          }

          .react-calendar__month-view__days {
            .current-date-highlight {
              abbr {
                text-decoration: underline;
                text-underline-position: under;
                font-size: 18px;
                // padding: 25px;
                padding-top: 25px;
                border-radius: 50%;
                padding-bottom: 20px;

                @media (max-width: 590px) {
                  font-size: 14px;
                }
              }
            }

            .disable-date-highlight {
              pointer-events: none;

              abbr {
                background: #d8d8d8 !important;
                font-size: 18px;
                padding: 10px 15px;
                border-radius: 50%;
                color: #48484a !important;
                font-weight: 600;
                font-family: "Poppins-Medium", sans-serif;

                @media (max-width: 590px) {
                  padding: 5px 7px;
                  font-size: 14px;
                }
              }
            }

            .react-calendar__tile:disabled {
              background-color: #ffffff !important;

              abbr:hover {
                background: #ffffff !important;
                font-size: 18px;
                padding: 25px;
                padding-top: 25px;
                border-radius: 50%;
                padding-bottom: 20px;
                color: #75757599 !important;
                font-weight: 500;
                font-family: "Poppins-Medium", sans-serif;

                @media (max-width: 590px) {
                  padding: 9px;
                  padding-top: 9px;
                  padding-bottom: 9px;
                  font-size: 14px;
                }
              }

              abbr {
                background: #ffffff !important;
                font-size: 18px;
                padding: 25px;
                padding-top: 25px;
                border-radius: 50%;
                padding-bottom: 20px;
                color: #75757599 !important;
                font-weight: 500;
                font-family: "Poppins-Medium", sans-serif;

                @media (max-width: 590px) {
                  padding: 9px;
                  padding-top: 9px;
                  padding-bottom: 9px;
                  font-size: 14px;
                }
              }
            }

            abbr {
              height: 58px;
              color: #48484a;
              font-size: 18px;
              font-weight: 400;
              line-height: 40px;
              text-align: center;

              @media (max-width: 590px) {
                font-size: 14px;
              }
            }

            .react-calendar__tile {
              border-radius: 50%;
              max-width: 86px;
              background: #ffffff;
            }

            .react-calendar__tile:hover {
              border-radius: 50%;
              max-width: 86px;
              background: #ffffff;
            }

            .react-calendar__tile {
              abbr:hover {
                color: #ffffff;
                background: #1b5add;
                font-size: 18px;
                padding: 10px 15px;
                border-radius: 50%;

                @media (max-width: 590px) {
                  padding: 4px 6px;
                  font-size: 14px;
                }
              }
            }

            .react-calendar__tile--active {
              background: #ffffff;
              border-radius: 50%;
              max-width: 86px;

              // margin-left: 12px;
              abbr {
                color: #ffffff;
                background: #1b5add;
                font-size: 18px;
                padding: 10px 15px;
                border-radius: 50%;
                @media (max-width: 590px) {
                  padding: 5px 7px;
                  font-size: 14px;
                }
              }
            }
          }
        }
      }
    }
  }
}

.Mui-expanded {
  border: 0px solid #757575 !important;

  #panel1a-header {
    display: none;
  }
}

.accordian-main-div {
  width: 100%;
  border-radius: 8px;
  border: 1px solid #757575;
  background: #ffffff;
  background-blend-mode: normal;
  margin-top: 40px;
  margin-bottom: 10px;
  box-shadow: 0px 4px 24px -10px rgba(0, 0, 0, 0.1) !important;

  .MuiCollapse-wrapper {
    overflow: auto;
    height: 211px;
  }

  .MuiCollapse-wrapper::-webkit-scrollbar {
    width: 6px;
    display: initial !important;
  }

  .MuiCollapse-wrapper::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px #e5e5ea;
    border-radius: 39px !important;
  }

  .MuiCollapse-wrapper::-webkit-scrollbar-thumb {
    background-color: #757575;
    border-radius: 39px !important;
  }

  #panel1a-header {
    width: 100%;
    padding-left: 26px;

    p {
      font-family: "Poppins-Medium", sans-serif;
      font-size: 16px;
      font-weight: 500;
      line-height: 18px;
      text-align: right;
    }
  }

  #panel1a-content {
    display: block;
    width: 539px;
    padding-left: 10;
    padding-right: 10;

    @media (max-width: 652px) {
      width: 80.109375vw;
    }

    @media (max-width: 460px) {
      width: 77vw;
    }

    @media (max-width: 375px) {
      width: 70vw;
    }

    hr {
      border: 0.5px solid #c7c7c763;
    }

    p {
      color: #2c2c2e;
      font-family: "Poppins-Medium", sans-serif;
      font-size: 16px;
      font-weight: 500;
    }
  }
}

.manage-booking-reschdule-calendar
  .timeslot-div
  .accordian-main-div
  #panel1a-header
  p {
  font-family: "Poppins-Medium", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  text-align: right;
}

.manage-booking-reschdule-calendar .timeslot-div .accordian-main-div {
  width: 100%;
  border-radius: 8px;
  border: 1px solid #757575;
  background: #fff;
  background-blend-mode: normal;
  margin-top: 40px;
  margin-bottom: 10px;
  box-shadow: 0 4px 24px -10px rgba(0, 0, 0, 0.1) !important;
}

.select-date-range-text {
  font-family: "Poppins-Medium", sans-serif;
  font-size: 24px;
  color: #000000;
  width: 100%;
  text-align: center;
  margin-top: 6%;
}

.reschedule-dialog-close-icon {
  height: 24px !important;
  width: 24px !important;
  margin: 0 !important;
}

.reschedule-dialog-title {
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  align-items: center;
}

.reschedule-ten-minutes-timer {
  font-family: "Poppins-Medium", sans-serif;
  font-size: 20px;
  color: #ff453a;
  direction: ltr;
}

.p-require-tag {
  text-align: initial;
  width: 100%;
  margin-top: 10px;
  color: red;
  font-size: 14px;
}
