.manage-catalouge-main-container {
  position: relative;
}

.manage-catalouge-content-container {
  position: absolute;
  width: 700px;
  top: 115px;
  left: 580px;
  height: 83vh;
  overflow-y: scroll;
  max-width: 100%;
  scrollbar-width: none !important;
}

.arabic-manage-catalouge-content-container {
  position: absolute;
  width: 700px;
  top: 115px;
  right: 580px;
  height: 83vh;
  overflow-y: scroll;
  max-width: 100%;
  scrollbar-width: none !important;
}

.manage-catalouge-content-container::-webkit-scrollbar,
.arabic-manage-catalouge-content-container::-webkit-scrollbar {
  display: none !important;
}

.manage-catalouge-heading-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.manage-catalouge-header-text {
  font-family: "Poppins-Medium", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  text-align: center;
  color: #2c2c2e;
}

.manage-catalouge-service-type-container {
  max-width: 140px;
  background: rgba(61, 64, 195, 0.8);
  border-radius: 14px;
  font-family: "Poppins-Medium", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: right;
  color: #ffffff;
  padding: 1px 10px;
}

.catalouge-main-content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.catalouge-main-container {
  width: 100%;
  background: #ffffff;
  border-radius: 12px;
  margin-top: 22px;
}

.catalouge-category-header-container {
  display: flex;
  min-height: 64px;
  flex-direction: row;
  justify-content: space-around;
  background-color: #1e5fea;
  border-radius: 12px 12px 0px 0px;
  align-items: center;
  height: auto;
}

.catalouge-category-header-container span {
  font-family: "Poppins-Medium", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #ffffff;
  width: 75%;
}

.catalouge-category-header-container img {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.catalouge-service-container {
  display: flex;
  height: 55px;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-top: 24px;
}

.catalouge-service-container div {
  width: 75%;
}

.catalouge-service-container img {
  cursor: pointer;
}

.catalouge-service-name-price-container {
  display: flex;
  flex-direction: column;
}

.catalouge-service-name-price-container span:first-child {
  font-family: "Poppins-Medium", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #2c2c2e;
}

.catalouge-service-name-price-container span:nth-child(2) {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  color: #636366;
}

.add-another-service-container {
  display: flex;
  height: 64px;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-top: 22px;
  margin-bottom: 22px;
}

.add-another-service-container span,
.add-edit-category-add-another-service-container span {
  font-family: "Poppins-Medium", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #48484a;
  width: 75%;
}

.add-another-service-container img,
.add-edit-category-add-another-service-container img {
  width: 22px;
  height: 22px;
  cursor: pointer;
}

.manage-catalouge-add-edit-category-heading-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.manage-catalouge-add-edit-category-heading-container img {
  width: 24px;
  height: 24px;
  margin-right: 12px;
  cursor: pointer;
}

.add-edit-category-main-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 92%;
  /* height: 422px; */
  height: auto;
  background-color: #ffffff;
  border-radius: 16px;
  margin-top: 24px;
  padding: 5% 0%;
}

.add-edit-category-input-container {
  display: flex;
  flex-direction: column;
  width: 85%;
}

.arabic-add-edit-category-input-container {
  flex-direction: column;
  display: flex;
  width: 85%;
  margin-top: 3%;
}

.add-edit-service-input-container {
  display: flex;
  flex-direction: column;
  width: 85%;
  margin-top: 24px;
}

.add-edit-service-price-input-container,
.add-edit-service-duration-input-container {
  width: 45%;
}

.add-edit-service-price-duration-input-container {
  display: flex;
  flex-direction: row;
  width: 85%;
  margin-top: 24px;
  justify-content: space-between;
}

.add-edit-category-input-container span:first-child,
.add-edit-service-input-container span:first-child,
.add-edit-service-price-input-container span:first-child,
.add-edit-service-duration-input-container span:first-child {
  font-family: "Poppins-Medium", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #2c2c2e;
  margin-bottom: 12px;
}

.arabic-add-edit-category-input-container span:first-child{
  font-family: "Poppins-Medium", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #2c2c2e;
  margin-bottom: 12px;
}

.add-edit-category-input-style {
  border-radius: 8px !important;
  font-family: "Poppins", sans-serif !important;
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 27px !important;
  color: #1c1c1e !important;
  width: 100% !important;
}

.add-category-save-button-style {
  width: 75% !important;
  height: 56px !important;
  background-color: #1e5fea !important;
  color: #ffffff !important;
  font-family: "Poppins-Medium", sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 18px !important;
  text-align: center !important;
  text-transform: none !important;
  border-radius: 28px !important;
  margin-top: 65px !important;
  margin-bottom: 12px !important;
}

.add-category-cancel-button-style {
  width: 75%;
  height: 56px;
  background-color: #ffffff !important;
  color: #ff453a !important;
  font-family: "Poppins-Medium", sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 18px !important;
  text-align: center !important;
  text-transform: none !important;
  border-radius: 28px !important;
  border: 1px solid #de350b !important;
}

.add-category-delete-button-style {
  width: 75% !important;
  height: 56px !important;
  background-color: #de350b !important;
  color: #ffffff !important;
  font-family: "Poppins-Medium", sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 18px !important;
  text-align: center !important;
  text-transform: none !important;
  border-radius: 28px !important;
}

.add-service-save-button-style {
  width: 75% !important;
  height: 56px !important;
  background-color: #1e5fea !important;
  color: #ffffff !important;
  font-family: "Poppins-Medium", sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 18px !important;
  text-align: center !important;
  text-transform: none !important;
  border-radius: 28px !important;
  margin-top: 30px !important;
  margin-bottom: 12px !important;
}

.add-edit-category-add-another-service-container {
  display: flex;
  width: 95%;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-top: 32px;
}

.error-message-text {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  color: #ff453a;
  margin-top: 12px;
}

.Mui-error {
  color: #ff453a !important;
}

.add-edit-service-main-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 92%;
  /* height: 760px; */
  height: auto;
  background-color: #ffffff;
  border-radius: 16px;
  margin-top: 24px;
  padding: 5% 0%;
}

.add-another-category-button {
  width: 75% !important;
  height: 56px !important;
  color: #2c2c2e !important;
  font-family: "Poppins-Medium", sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 18px !important;
  text-align: center !important;
  text-transform: none !important;
  border-radius: 28px !important;
  border: 1px solid #2c2c2e !important;
  margin-top: 44px !important;
  margin-bottom: 44px !important;
}

.add-another-category-button img {
  margin-right: 8px !important;
}

.delete-modal-no-button-style {
  width: 100% !important;
  height: 56px !important;
  background-color: #1e5fea !important;
  color: #ffffff !important;
  font-family: "Poppins-Medium", sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 18px !important;
  text-align: center !important;
  text-transform: none !important;
  border-radius: 28px !important;
}

.delete-modal-yes-button-style {
  width: 100% !important;
  height: 56px !important;
  background-color: #ffffff !important;
  color: #ff453a !important;
  font-family: "Poppins-Medium", sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 18px !important;
  text-align: center !important;
  text-transform: none !important;
  border-radius: 28px !important;
  border: 1px solid #de350b !important;
  margin-top: 10px !important;
}

.unsubscribed-modal-no-button-style {
  width: 100% !important;
  height: 56px !important;
  background-color: #ff453a !important;
  color: #ffffff !important;
  font-family: "Poppins-Medium", sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 18px !important;
  text-align: center !important;
  text-transform: none !important;
  border-radius: 28px !important;
  border: 1px solid #de350b !important;
  margin-top: 10px !important;
}

.unsubscribed-user-message-container {
  width: 100%;
  height: 58px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #f9f9f8;
  border-radius: 8px;
  margin-top: 20px;
}

.unsubscribed-user-confirmation-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 490px;
  height: 570px;
  transform: translate(-50%, -50%);
  background: #ffffff;
  border-radius: 24px;
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.add-new-categories-message-text {
  font-style: normal;
  font-size: 22px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: #2c2c2e;
  text-align: center;
  margin-top: 25px;
}

::-webkit-scrollbar {
  display: none !important;
}

@media (min-width: 1024px) and (max-width: 1290px) {
  .manage-catalouge-content-container {
    width: 520px;
    left: 480px;
  }

  .arabic-manage-catalouge-content-container {
    width: 520px;
    left: 480px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .manage-catalouge-content-container {
    width: 340px;
    left: 410px;
  }

  .arabic-manage-catalouge-content-container {
    width: 340px;
    left: 410px;
  }
}

@media (max-width: 590px) {
  .unsubscribed-user-confirmation-modal,
  .logout_modal,
  .review-success-modal-manage-business,
  .customer-review-manage-booking-modal {
    width: 310px !important;
  }

  .unsubscribed-user-confirmation-modal .logout_confirmation_text {
    font-size: 16px;
  }

  .unsubscribed-user-message-text {
    font-size: 10px;
  }

  .customer-review-manage-booking-header {
    font-size: 20px !important;
  }
}

@media screen and (max-width: 767px) {
  .manage-catalouge-content-container {
    left: 50px;
    top: 750px;
    width: 85%;
  }

  .arabic-manage-catalouge-content-container {
    right: 50px;
    top: 750px;
    width: 85%;
    margin-top: 60px;
  }
}
