.parent-menu-subcategory-div {
  max-width: 100%;
}

.menu-subcategory-div {
  color: #757575;
  font-family: Poppins, "Gotham", "Courier New", Courier, monospace;
  font-weight: 500;
  display: flex;
  flex-wrap: wrap;
  margin: 0px 40px;
  max-width: 1440px;
}

.list-element-div {
  overflow: auto !important;
  text-align: -webkit-center;
  text-align: -moz-center;
  background: #f9f9f8;
  height: 100%;
}

.business-list-sub-container {
  @media (max-width: 845px) {
    padding: 20px;
  }
}

.list-element-div::-webkit-scrollbar {
  width: 6px;
}

.list-element-div::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.list-element-div::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.87);
  color: black;
}

.MuiBreadcrumbs-li {
  p {
    font-size: 16px;
    cursor: pointer;
  }

  font-size: 16px;
  cursor: pointer;
}

.MuiSvgIcon-root,
.MuiSvgIcon-fontSizeSmall {
  font-size: 16px !important;
  cursor: pointer;
}

.parent-business-details-container{
  max-width: 100%;
  min-height: 55%;
}