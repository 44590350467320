.payments-method-in-business-main-container {
  display: flex;
  flex-direction: column;
  margin-top: 12%;
}

.payments-method-in-business-heading-text {
  display: flex;
  font-size: 24px;
  font-family: "Poppins-Medium", sans-serif;
  color: #2c2c2c;
}

.payments-method-in-business-cards-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  height: 730px;
  width: 530px;
  margin-top: 3%;
  border-radius: 16px;
  padding: 4%;
  max-width: 100%;
}

.payments-method-in-business-cards-sub-container {
  width: 100%;
  height: 100%;
  display: flex;
  overflow: scroll;
  align-items: center;
  flex-direction: column;
}

.no-saved-card-image {
  height: 192px;
  width: 192px;
  margin-bottom: 4%;
}

.no-card-attached-text {
  font-family: "Poppins-Medium", sans-serif;
  font-size: 20px;
  color: #2c2c2c;
  margin-bottom: 2%;
}

.please-attach-card-message-text {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  color: #2c2c2c;
  width: 70%;
}

.payment-details-in-business-card-details-container {
  width: 100%;
  height: 96px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #f9f9f8;
  margin-bottom: 4%;
  border-radius: 12px;
}

.payment-details-in-business-card-details-brand-image {
  height: 48px;
  width: 48px;
  margin: 0% 4%;
}

.payment-details-in-business-card-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.payment-details-in-business-card-brand-details {
  font-family: "Poppins-Medium", sans-serif;
  font-size: 18;
  color: #2c2c2e;
}

.payment-details-in-business-card-number-details {
  font-family: "Poppins", sans-serif;
  font-size: 16;
  color: #48484a;
}
