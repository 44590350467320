.editprofile-papar-container {
    display: flex;
    width: 100% !important;
    height: 100% !important;
    border-radius: 16px !important;
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }

  .profile-header-main-item-text {
    font-family: "Poppins-Medium", sans-serif !important;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    color: #2c2c2e;
    cursor: pointer;
    margin-left: 10px;  
  }

  .card-details-style{
    padding: 30px;
    border-radius: 16px !important;
    width: 100%;
  }

  .profile-root-style-card {
    height: 300px !important;
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .form-label-profile {
    font-family: "Poppins-Medium", sans-serif !important;
    display: flex;
    position: relative;
    color: #2C2C2E;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    margin-top: 20px !important;
  }

  .form-select-input-style-profile {
    margin-top: 14px;
}

.custom-select-profile {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 54px;
  border: 1px solid #757575;
  border-radius: 8px;
  overflow: hidden;
  font-family: "Poppins-Medium", sans-serif !important;
}

.custom-select-profile select {
width: 100%;
height: 100%;
padding: 10px;
font-size: 16px;
border: none;
outline: none;
background-color: transparent;
font-family: "Poppins-Medium", sans-serif !important;
appearance: none; /* Hide default dropdown arrow icon */
}

.custom-select-profile img{
position: absolute;
top: 50%;
right: 10px;
margin-right: 5px;
transform: translateY(-50%);
width: 24px;
height: 24px;
}

.input-container-left-edit-profile {
  display: flex;
  flex-direction: column;
}

.input-container-button-edit-profile {
  display: flex;
  justify-content: center;
}

.team-icon-edit-profile {
  display: flex;
}

.edit-profile-next-Step-button {
  height: 56px !important;
  background-color: #1e5fea !important;
  margin-top: 40px !important;
  color: #ffffff !important;
  border-radius: 25px !important;
  font-family: 'Poppins', sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 18px !important;
  text-align: center !important;
  text-transform: none !important;
}
.dialog-button {
  width: 100%;
  border-radius: 24px !important;
  padding: 10px !important;
  text-transform: none !important;
}
@media only screen and (max-width:500px) {
  .button-text{
    font-size: 12px !important;
  }
}