.modal-booknow {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 407px;
    height: 535px;
    border-radius: 24px;
    background: #ffffff;
    background-blend-mode: normal;

    @media (max-width:428px) {
        width: 100%;
    }

    .debit-first-div {
        text-align: end;
        margin-top: 10px;

        img {
            cursor: pointer;
            width: 50px;
            height: 50px;
        }
    }

    .h3-tag-debit {
        color: #2c2c2e;
        font-family: "Poppins-medium", "Gotham", "Courier New", Courier, monospace;
        font-size: 14px;
        font-weight: 500;
        line-height: 16px;
        text-align: center;
        margin: 30px;
    }

    .p-tag-debit {
        color: #3a3a3ccf;
        font-family: "Poppins-medium", "Gotham", "Courier New", Courier, monospace;
        font-size: 14px;
        font-weight: 400;
        line-height: 14px;
        text-align: center;
        height: auto;
    }

    .h1-tag-debit {
        color: #2c2c2e;
        font-family: "Poppins-medium", "Gotham", "Courier New", Courier, monospace;
        font-size: 38px;
        font-weight: 500;
        line-height: 42px;
        text-align: center;
        margin-left: 0px;
        height: auto;
    }

    .img-debit-card-div {
       
        .img-debit-card{
            width: 327px;
            height: 196px;
            border-radius: 14px;
            background: #433fbd;
            margin: auto;
            padding: 10px;
        }
    }

    .first-div-of-modal {
        display: flex;
        flex-wrap: wrap;

        h2 {
            height: 26px;
            color: #2c2c2e;
            font-family: "Poppins-medium", "Gotham", "Courier New", Courier, monospace;
            font-size: 24px;
            font-weight: 500;
            line-height: 26px;
            text-align: center;
            margin: 30px;
            margin-left: auto;
            padding-left: 80px;
        }

        img {
            margin-left: auto;
            cursor: pointer;
        }
    }

    p {
        height: 224px;
        color: #2c2c2e;
        font-size: 18px;
        font-weight: 400;
        line-height: 28px;
        overflow: auto;
        margin: 25px;
    }

    h1 {
        height: 24px;
        color: #2c2c2e;
        font-family: "Poppins-medium", "Gotham", "Courier New", Courier, monospace;
        font-size: 20px;
        font-weight: 500;
        line-height: 24px;
        margin-left: 25px;
    }

    h3 {
        height: 16px;
        color: #2c2c2e;
        font-family: "Poppins-medium", "Gotham", "Courier New", Courier, monospace;
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        margin-left: 25px;
        margin-top: 10px;
    }

    .book-now-btn-modal {
        width: 88%;
        height: 52px;
        background: #1e5fea;
        background-blend-mode: normal;
        border-radius: 24px;
        color: #ffffff;
        margin: 25px;
        margin-top: 44px;
        font-family: Poppins-Medium, sans-serif;
        font-size: 18px;
        font-weight: 500;
        line-height: 16px;
        text-transform: capitalize;
    }

    .book-now-btn-modal-debit {
        width: 88%;
        height: 52px;
        background: #1e5fea;
        background-blend-mode: normal;
        border-radius: 24px;
        color: #ffffff;
        margin: 25px;
        margin-top: 10px;
        font-family: Poppins-Medium, sans-serif;
        font-size: 18px;
        font-weight: 500;
        line-height: 16px;
        text-transform: capitalize;

        span {
            color: #ffffff;
            font-family: 'Poppins-Medium';
            font-size: 18px;
            font-weight: 500;
            line-height: 18px;
            text-align: center;
        }
    }

    .book-now-btn-modal-debit:hover {
        width: 88%;
        height: 52px;
        background: #1e5fea;
        background-blend-mode: normal;
        border-radius: 24px;
        color: #ffffff;
        margin: 25px;
        margin-top: 10px;
        font-family: Poppins-Medium, sans-serif;
        font-size: 18px;
        font-weight: 500;
        line-height: 16px;
        text-transform: capitalize;

        span {
            color: #ffffff;
            font-family: 'Poppins-Medium';
            font-size: 18px;
            font-weight: 500;
            line-height: 18px;
            text-align: center;
        }
    }

    .book-now-btn-modal:hover {
        width: 88%;
        height: 52px;
        background: #1e5fea;
        background-blend-mode: normal;
        border-radius: 24px;
        color: #ffffff;
        margin: 25px;
        margin-top: 44px;
        font-family: Poppins-Medium, sans-serif;
        font-size: 18px;
        font-weight: 500;
        line-height: 16px;
        text-transform: capitalize;
    }

    .h1-tag-modal {
        color: #2c2c2e;
        font-family: "Poppins-medium", "Gotham", "Courier New", Courier, monospace;
        font-size: 24px;
        font-weight: 500;
        line-height: 24px;
        text-align: center;
        margin-top: 30px;
    }

    .h1-tag-modal-1 {
        color: #2c2c2e;
        font-family: "Poppins-medium", "Gotham", "Courier New", Courier, monospace;
        font-size: 16px;
        font-weight: 500;
        line-height: 21px;
        text-align: center;
        margin-top: 75px;
        margin-left: 0px !important;
    }

    .fader-Loader {
        display: inherit !important;
        position: relative !important;
        font-size: 0px !important;
        top: 20px !important;
        left: 135px !important;
        width: 60px !important;
        height: 60px !important;
    }

    .arabic-fader-Loader {
        display: inherit !important;
        position: relative !important;
        font-size: 0px !important;
        top: 20px !important;
        right: 135px !important;
        width: 60px !important;
        height: 60px !important;
    }
}