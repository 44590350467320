* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

.constainer01 {
  width: 100%;
  background-color: #f2f2f2;
  height: 100%;
}

.customer-home-screen-main-container {
  max-width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.subheader {
  display: flex;
  justify-content: space-between;
  width: max-content;
  height: auto;
  align-items: center;
}

.subheader img {
  float: right;
}

.constainer01 .header img {
  width: 15%;
  height: 30%;
}

.constainer01 .header .search_bar_div button {
  width: 54px;
  height: 54px;
}

.constainer01 .header .subheader .paste_image {
  margin-top: 3%;
  margin-right: 10%;
  width: 32px;
  height: 32px;
}

.constainer01 .header .subheader .groupimage {
  width: 54px;
  height: 54px;
  cursor: pointer;
  margin-left: 40px;
}

.search_bar_div {
  width: 92%;
  border-radius: 50px;
  height: 100%;
  border: 1.5px solid black;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.search_bar_div button img {
  border-radius: 50%;
}

.search_bar_div button {
  background-color: #0040ff;
  cursor: pointer;
  border-radius: 50%;
  border: 0;
}
.search_bar {
  width: 100%;
  background: transparent;
  height: 100%;
  border: 0;
  padding-left: 25px;
  outline: none;
  border-radius: 20%;
  overflow: hidden;
}
.searchBar_with_location {
  width: 75%;
  height: 54px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.location_with_text {
  width: 42%;
  display: flex;
  flex-direction: row;
  margin: auto;
  margin-top: 12px;
}

.location_with_text h6 {
  margin: 0px 8px;
}

.searchBar_location_navigation {
  display: flex;
  flex-direction: column;
  width: 65%;
  align-items: center;
  justify-content: center;
}
.dot_img {
  margin-bottom: 10px;
}

.Puppets {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  width: 40%;
  margin: auto;
  margin-bottom: 60px;
}

.gudda {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.gudhiya {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.gudda_gudhiya {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.squeeze_data_only {
  text-align: center;
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
}

.footer {
  width: 100%;
  border: 1px solid black;
  background-color: black;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 15px;
  height: max-content;
  flex-wrap: wrap;
  padding-bottom: 15px;
}
.squeez_white_img {
  margin-top: 50px;
}
.footer_one h4,
.footer_one h5 {
  color: aliceblue;
}

.footer_one {
  margin-top: 50px;
}

.footer_two h4,
.footer_two h5 {
  color: aliceblue;
}

.footer_two {
  margin-top: 50px;
}

.footer_three h4,
.footer_three h5 {
  color: aliceblue;
}

.footer_three {
  margin-top: 50px;
}

@media only screen and (min-width: 200px) and (max-width: 567px) {
  .footer {
    height: 800px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .footer img {
    display: none;
  }

  .constainer01 .header img {
    width: 50px;
    height: 20%;
  }

  /* --------------------------------------------------------------- */
  .model_popup01 .contents01 .divs01 .first_row_second01 {
    width: 100px;
  }

  .model_popup01 .contents01 {
    width: 267px;
    position: absolute;
    top: 25%;
    left: 13%;
  }

  .model_popup01 .contents01 .divs01 .first_row01 {
    width: 150px;
    float: right;
  }
  /* --------------------------------------------------- */

  .model_popup02 .contents02 .divs02 .first_row_second02 {
    width: 100px;
  }

  .model_popup02 .contents02 {
    width: 280px;
    position: absolute;
    top: 25%;
    left: 13%;
  }

  .model_popup02 .contents02 .divs02 .first_row02 {
    width: 150px;
    float: right;
  }
  /* ----------------------------------------------- */
  .model_popup03 .contents03 .divs03 .first_row_second03 {
    width: 30px;
  }

  .model_popup03 .contents03 {
    width: 280px;
    position: absolute;
    top: 25%;
    left: 13%;
  }

  .model_popup03 .contents03 .divs03 .first_row03 {
    width: 216px;
    float: right;
  }

  .model_popup03 .contents03 .pic_one03 {
    margin: 20px 0px 30px 30px;
  }

  .model_popup03 .contents03 .pic_two03 {
    margin: 0px 30px 30px 30px;
  }

  .model_popup01 .contents01 .pic_two01 {
    margin: 20px 30px 0px 30px;
  }

  .model_popup02 .contents02 .pic_two02 {
    margin: 20px 30px 0px 30px;
  }
}

@media only screen and (min-width: 568px) and (max-width: 768px) {
  .model_popup01 .contents01 {
    width: 267px;
    position: absolute;
    top: 25%;
    left: 35%;
  }

  .model_popup01 .contents01 .divs01 .first_row_second01 {
    width: 80%;
  }
  /* -------------------------------------------------- */
  .model_popup02 .contents02 {
    width: 268px;
    position: absolute;
    top: 25%;
    left: 35%;
  }

  .model_popup02 .contents02 .divs02 .first_row_second02 {
    width: 50%;
  }
  /* -------------------------------------------------------- */

  .model_popup03 .contents03 {
    width: 268px;
    position: absolute;
    top: 25%;
    left: 35%;
  }

  .model_popup03 .contents03 .divs03 .first_row_second03 {
    width: 13%;
  }
  .model_popup03 .contents03 .divs03 .first_row03 {
    width: 241px;
  }

  .model_popup03 .contents03 .pic_one03 {
    margin: 20px 0px 30px 30px;
  }

  .model_popup03 .contents03 .pic_two03 {
    margin: 0px 30px 30px 30px;
  }

  .model_popup01 .contents01 .pic_two01 {
    margin: 20px 30px 0px 30px;
  }

  .model_popup02 .contents02 .divs02 .first_row02 {
    width: 241px;
  }

  .model_popup02 .contents02 .pic_two02 {
    margin: 20px 30px 0px 30px;
  }
}
@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .model_popup01 .contents01 {
    width: 480px;
    position: absolute;
    top: 25%;
    left: 25%;
  }
  .model_popup01 .contents01 .divs01 .first_row_second01 {
    width: 200px;
  }

  .model_popup01 .contents01 .divs01 .first_row01 {
    width: 226px;
  }
  /* -------------------------------------------------------------------- */

  .model_popup02 .contents02 {
    width: 480px;
    position: absolute;
    top: 25%;
    left: 25%;
  }
  .model_popup02 .contents02 .divs02 .first_row_second02 {
    width: 200px;
  }

  .model_popup02 .contents02 .divs02 .first_row02 {
    width: 226px;
  }
  /* ------------------------------------------------------------- */
  .model_popup03 .contents03 {
    width: 480px;
    position: absolute;
    top: 25%;
    left: 25%;
  }
  .model_popup03 .contents03 .divs03 .first_row_second03 {
    width: 150px;
  }

  .model_popup03 .contents03 .divs03 .first_row03 {
    width: 300px;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1200px) {
  .model_popup01 .contents01 {
    width: 480px;
    position: absolute;
    top: 25%;
    left: 28%;
  }
  .model_popup01 .contents01 .divs01 .first_row_second01 {
    width: 200px;
  }

  .model_popup01 .contents01 .divs01 .first_row01 {
    width: 226px;
  }
  /* ------------------------------------------------------------------------- */

  .model_popup02 .contents02 {
    width: 480px;
    position: absolute;
    top: 25%;
    left: 28%;
  }
  .model_popup02 .contents02 .divs02 .first_row_second02 {
    width: 200px;
  }

  .model_popup02 .contents02 .divs02 .first_row02 {
    width: 226px;
  }
  /* ------------------------------------------------------------- */
  .model_popup03 .contents03 {
    width: 480px;
    position: absolute;
    top: 25%;
    left: 28%;
  }
  .model_popup03 .contents03 .divs03.first_row_second03 {
    width: 200px;
  }

  .model_popup03 .contents03 .divs03 .first_row03 {
    width: 299px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 2000px) {
  .model_popup03 .contents03 {
    width: 480px;
    position: absolute;
    top: 25%;
    left: 30%;
  }
  .model_popup03 .contents03 .divs03.first_row_second03 {
    width: 200px;
  }

  .model_popup03 .contents03 .divs03 .first_row03 {
    width: 300px;
  }
  /* ------------------------------------------------------------------ */

  .model_popup02 .contents02 {
    width: 480px;
    position: absolute;
    top: 25%;
    left: 30%;
  }
  .model_popup02 .contents02 .divs02.first_row_second02 {
    width: 200px;
  }
  .model_popup02 .contents02 .divs02 .first_row02 {
    width: 226px;
  }
  /* ------------------------------------------------------------------- */

  .model_popup01 .contents01 {
    width: 480px;
    position: absolute;
    top: 25%;
    left: 30%;
  }
  .model_popup01 .contents01 .divs01.first_row_second01 {
    width: 200px;
  }
  .model_popup01 .contents01 .divs01 .first_row01 {
    width: 226px;
  }
}

@media only screen and (min-width: 2000px) and (max-width: 3000px) {
  .header {
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .subheader {
    display: flex;
    justify-content: space-between;
    width: 5%;
    height: auto;
    align-items: center;
  }

  .subheader img {
    float: right;
  }

  .constainer01 .header img {
    width: 15%;
    height: 30%;
  }

  .search_bar_div {
    width: 72.5%;
    border-radius: 50px;
    height: 100%;
    border: 1.5px solid black;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .search_bar_div button img {
    border-radius: 50%;
  }

  .constainer01 .header .search_bar_div button {
    background-color: #0040ff;
    cursor: pointer;
    border-radius: 50%;
    border: 0;
    width: 50px;
    height: 50px;
  }

  .constainer01 .header .search_bar_div input::placeholder {
    font-size: 25px;
    padding-left: 10px;
    padding-top: 3px;
  }

  .constainer01 .header .subheader .paste_image {
    margin-top: 3%;
    margin-right: 10%;
    width: 30px;
    height: 30px;
  }

  .constainer01 .header .subheader .groupimage {
    width: 52px;
    height: 52px;
    cursor: pointer;
    margin-right: 1%;
  }

  .search_bar {
    width: 73%;
    height: 40px;
    border: 0;
    margin: 3px;
    outline: none;
    border-radius: 30%;
  }

  /* -------------------------------------------------------------- */
  .model_popup03 .contents03 {
    width: 480px;
    position: absolute;
    top: 25%;
    left: 40%;
  }
  .model_popup03 .contents03 .divs03.first_row_second03 {
    width: 200px;
  }

  .model_popup03 .contents03 .divs03 .first_row03 {
    width: 300px;
  }
  /* ------------------------------------------------------------------ */

  .model_popup02 .contents02 {
    width: 480px;
    position: absolute;
    top: 25%;
    left: 40%;
  }
  .model_popup02 .contents02 .divs02.first_row_second02 {
    width: 200px;
  }
  .model_popup02 .contents02 .divs02 .first_row02 {
    width: 226px;
  }
  /* ------------------------------------------------------------------- */

  .model_popup01 .contents01 {
    width: 480px;
    position: absolute;
    top: 25%;
    left: 40%;
  }
  .model_popup01 .contents01 .divs01.first_row_second01 {
    width: 200px;
  }
  .model_popup01 .contents01 .divs01 .first_row01 {
    width: 226px;
  }
}
.squeez_big_image_second {
  margin-bottom: 70px;
}

.margin_bottom_class {
  margin-bottom: 15px;
}

.pic_one01 img {
  width: 200px;
  height: 120px;
}

.pic_one01 {
  margin: 20px 0px 0px 30px;
}

.pic_two01 {
  margin: 20px 30px 0px 20px;
}

.pic_three01 {
  margin: 10px 0px 30px 30px;
}

.pic_two01 img {
  width: 200px;
  height: 120px;
}

.pic_three01 img {
  width: 200px;
  height: 120px;
}

.first_row01 {
  width: 250px;
  height: 30px;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.first_row_second01 {
  width: 230px;
  height: 30px;
  margin-top: 20px;
}

.divs01 {
  display: flex;
  flex-direction: row;
}

.first_row01 h2 {
  margin: 0px;
}

.first_row01 h1 {
  margin: 0px;
}

.back_shade01 {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1;
}

.contents01 {
  width: 40%;
  z-index: 2;
  position: absolute;
  top: 50%;
  left: 30%;
  background: #fff;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 15px;
}

.pic_one02 img {
  width: 200px;
  height: 120px;
}

.pic_one02 {
  margin: 20px 0px 0px 30px;
}

.pic_two02 {
  margin: 20px 30px 0px 20px;
}

.pic_three02 {
  margin: 10px 0px 30px 30px;
}

.pic_two02 img {
  width: 200px;
  height: 120px;
}

.pic_three02 img {
  width: 200px;
  height: 120px;
}

.first_row02 {
  width: 280px;
  height: 30px;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.first_row_second02 {
  width: 200px;
  height: 30px;
  margin-top: 20px;
}

.divs02 {
  display: flex;
  flex-direction: row;
}

.first_row02 h2 {
  margin: 0px;
}

.first_row02 h1 {
  margin: 0px;
}

.back_shade02 {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1;
}

.contents02 {
  width: 40%;
  z-index: 2;
  position: absolute;
  top: 50%;
  left: 30%;
  background: #fff;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 15px;
}

.pic_one03 img {
  width: 200px;
  height: 120px;
}

.pic_one03 {
  margin: 40px 0px 30px 30px;
}

.pic_two03 {
  margin: 40px 30px 30px 20px;
}

.pic_two03 img {
  width: 200px;
  height: 120px;
}

.pic_three03 img {
  width: 220px;
  height: 120px;
}

.first_row03 {
  width: 300px;
  height: 30px;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.first_row_second03 {
  width: 150px;
  height: 30px;
  margin-top: 20px;
}

.divs03 {
  display: flex;
  flex-direction: row;
}

.first_row03 h2 {
  margin: 0px;
}

.first_row03 h1 {
  margin: 0px;
}

.back_shade03 {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1;
}

.contents03 {
  width: 40%;
  z-index: 2;
  position: absolute;
  top: 50%;
  left: 30%;
  background: #fff;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 15px;
}

.nav_alert_popup .overlay {
  width: 100vw;
  height: 100vh;
  background-color: black;
  position: fixed;
  opacity: 0.7;
}

.nav_alert_popup .content {
  position: absolute;
  top: 0%;
  left: 34%;
  width: 30%;
  height: 450px;
  border: 1px solid black;
  text-align: center;
  z-index: 2;
  background: #fff;
  padding: 2%;
  border-radius: 20px;
}

.nav_alert_popup .content h4 {
  margin-bottom: 10px;
  margin-top: 10px;
}

.nav_alert_popup .content p {
  margin-bottom: 10px;
}

.nav_alert_popup .content .enable_location_button {
  width: 90%;
  margin-top: 10px;
}

.nav_alert_popup .content h5 {
  margin-top: 10px;
  cursor: pointer;
}

.services-text-customer-home-screen {
  font-family: "Poppins-Medium", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
  color: #2c2c2e;
  margin-top: 48px;
  margin-bottom: 32px;
}

.customer-home-screen-service-type-text {
  font-family: "Poppins-Medium", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #2c2c2e;
  margin-top: 12px;
}

.main-header-container {
  padding: 29px 40px;
}

.Squeeze_image_big_second {
  width: 95%;
  margin: auto;
  margin-bottom: 5%;
  direction: initial;
}

.Squeeze_image_big_first {
  width: 95%;
  margin: auto;
  direction: initial;
}

.logout-modal-no-button-style {
  height: 56px !important;
  width: 100% !important;
  font-family: "Poppins", sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 21px !important;
  display: flex !important;
  align-items: center !important;
  text-align: center !important;
  text-decoration: none !important;
  text-transform: none !important;
  background-color: #1e5fea !important;
  color: #fff !important;
  border-radius: 30px !important;
  margin-bottom: 12px !important;
}

.notification_ask_maybe_later_button {
  height: 56px !important;
  width: 100% !important;
  font-family: "Poppins", sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 21px !important;
  display: flex !important;
  align-items: center !important;
  text-align: center !important;
  text-decoration: none !important;
  text-transform: none !important;
  background-color: transparent !important;
  color: #2c2c2e !important;
  border-radius: 30px !important;
  border: none !important;
}

.logout-modal-yes-button-style {
  height: 56px !important;
  width: 100% !important;
  font-family: "Poppins", sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 21px !important;
  display: flex !important;
  align-items: center !important;
  text-align: center !important;
  text-decoration: none !important;
  text-transform: none !important;
  background-color: #ffffff !important;
  color: #ff453a !important;
  border: 1px solid #ff453a !important;
  border-radius: 30px !important;
}

.cookies-modal {
  width: 30%;
  height: auto;
  position: fixed;
  inset: auto 10px 10px auto !important;
}

@media only screen and (max-width: 767px) {
  .cookies-modal {
    width: 85%;
  }
}

.cookie-container {
  background-color: #ffffff;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.cookie-header {
  font-family: "Poppins", sans-serif !important;
  font-size: 25px;
  font-weight: bold;
  color: #3a479c;
}

.cookie-content {
  margin: 20 10;
  font-family: "Poppins", sans-serif !important;
}

.cookie-button-container {
  /* margin-top: 20px; */
  display: flex;
  width: 100%;
  justify-content: space-around;
}

.cookie-accept-button {
  width: 45% !important;
  font-family: "Poppins", sans-serif !important;
}

.cookie-decline-button {
  width: 45% !important;
  font-family: "Poppins", sans-serif !important;
}
