.home-appointment-details-container {
  display: flex;
  flex-direction: column;
  min-width: 110px;
  height: 74px;
  margin-left: 10;
  border-radius: 12px;
  background-color: #ffffff;
  justify-content: center;
  padding: 5;
  align-items: baseline;
}

.detailed-appointment-details-container {
  margin: 5;
  border-radius: 12px;
  background-color: #ffffff;
  justify-content: center;
  padding: 10;
}

.calendar-event-time-text {
  font-size: 16px;
  font-family: "Poppins-Medium", sans-serif;
  color: #2c2c2e;
  line-height: 18px;
}

.business-day-off-text {
  font-size: 24px;
  line-height: 36px;
  font-family: "Poppins-Medium", sans-serif;
  color: #ffffff;
}

.business-day-off-text-container {
  display: flex;
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 85%;
  background-color: #7677d3;
}

.calendar-event-text-style {
  font-size: 14px;
  line-height: 21px;
  font-family: "Poppins-Medium", sans-serif;
  color: #757575;
}

.border-line-in-calendar-event {
  margin: 10px;
  background-color: #999999;
  height: 1px;
  width: 100%;
}

.weekend-time-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 20px 20px 90px 20px;
}

.working-day-time-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px 20px 90px 20px;
  width: 10%;
}

.working-day-calendar-event-container {
  display: flex;
  flex-direction: column;
  height: 55vh;
  overflow: scroll;
  scrollbar-width: none !important;
}

.working-day-calendar-event-container::-webkit-scrollbar { 
  display: none !important;
}

.home-calendar-event-service-detail-image-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.home-appointment-detail-image {
  width: 16;
  height: 16;
  margin-left: 6px;
  border-radius: 50%;
}

.calendar-event-client-text-style {
  font-size: 14px;
  line-height: 21px;
  font-family: "Poppins-Medium", sans-serif;
  color: #757575;
  margin: 0px 10px;
}

.calendar-event-all-events-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: auto;
  flex-wrap: wrap;
}

.working-day-calendar-event-time-and-detail-container {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.working-day-calendar-event-right-side-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
}

.weeked-calendar-event-container {
  width: 15%;
  height: 100vh;
  overflow: scroll;
}

.weeked-calendar-event-main-container {
  display: flex;
  flex-direction: row;
}
