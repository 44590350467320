.add-member-calendar-container {
    text-align: -webkit-center;
    text-align: -moz-center;

    .calendar-close{
        background: rgb(255, 255, 255) none repeat scroll 0% 0%;
        border: medium none;
        position: absolute;
        right: 23px;
        font-size: 32px;
        cursor: pointer;
        top: 8px;
    }

    .react-calendar {
        width: 350px;
        height: auto;
        border-radius: 24px;
        background: #ffffff;
        background-blend-mode: normal;
        border: none;

        .react-calendar__navigation:hover {
            background: #ffffff;
        }

        

        .react-calendar__navigation {
            width: 100% !important;
            padding-top: 40px;
            padding-bottom: 30px;
            align-items: center;
            justify-content: center;

            .react-calendar__navigation__label {
                height: 46px;
                color: #2c2c2e;
                font-family: Poppins-Medium, sans-serif;
                font-size: 20px;
                font-weight: 600;
                line-height: 40px;
                flex-grow: 0 !important;

                span {
                    padding-left: 20px;
                    padding-right: 20px;
                }
            }

            button{
                font-size: 36px;
            }

            button:disabled {
                background-color: #ffffff !important;
            }

            .react-calendar__navigation__prev2-button {
                display: none;
            }

            .react-calendar__navigation__next2-button {
                display: none;
            }
        }

        .react-calendar__viewContainer {
            .react-calendar__month-view {
                padding-left: 20px;
                padding-right: 20px;

                .react-calendar__month-view__weekdays {
                    abbr {
                        text-decoration: none !important;
                        color: #48484a;
                        font-size: 15px;
                        font-weight: 200;
                        line-height: 14px;
                        text-align: center;
                        font-family: 'Poppins-Medium';
                        text-transform: uppercase;

                        @media (max-width:500px) {
                            font-size: 18px;
                        }
                    }
                }

                .react-calendar__month-view__days {
                    .current-date-highlight {
                        abbr {
                            text-decoration: underline;
                            text-underline-position: under;
                            height: 44px;
                            font-size: 18px;
                            padding-top: 25px;
                            border-radius: 61px;
                            padding-bottom: 20px;
                        }
                    }

                    .disable-date-highlight {
                        pointer-events: none;

                        abbr {
                            background: #d8d8d8 !important;
                            height: 44px;
                            font-size: 18px;
                            padding-top: 25px;
                            border-radius: 61px;
                            padding-bottom: 20px;
                            color: #48484a !important;
                            font-weight: 600;
                            font-family: 'Poppins-Medium';

                            @media (max-width:590px) {
                                padding: 9px;
                                padding-top: 9px;
                                padding-bottom: 9px;
                            }
                        }
                    }

                    .react-calendar__tile:disabled {
                        background-color: #ffffff !important;

                        abbr:hover {
                            background: #ffffff !important;
                            color: #757474 !important;
                            font-weight: 500;
                            font-family: 'Poppins-Medium';
                            height: 34px; 
                            font-size: 18px; 
                            border-radius: 48px; 
                            display: initial; 
                            width: 34px; 
                            align-content: center; 
                            justify-content: center; 


                            @media (max-width:590px) {
                                padding: 9px;
                                padding-top: 9px;
                                padding-bottom: 9px;
                            }
                        }

                        abbr {
                            background: #ffffff !important;
                            height: 44px;
                            font-size: 18px;
                            padding-top: 25px;
                            border-radius: 61px;
                            padding-bottom: 20px;
                            color: #75757599 !important;
                            font-weight: 500;
                            font-family: 'Poppins-Medium';

                            @media (max-width:590px) {
                                padding: 9px;
                                padding-top: 9px;
                                padding-bottom: 9px;
                            }
                        }
                    }


                    abbr {
                        height: 58px;
                        color: #48484a;
                        font-size: 18px;
                        font-weight: 400;
                        line-height: 40px;
                        text-align: center;
                    }

                    .react-calendar__tile {
                        height: 44px;
                        // border-radius: 61px;
                        // max-width: 86px;
                        background: #ffffff;
                    }

                    .react-calendar__tile:hover {
                        height: 44px;
                        // border-radius: 61px;
                        // max-width: 86px;
                        background: #ffffff;
                    }

                    .react-calendar__tile {
                        abbr:hover {
                            color: #ffffff; 
                            background: #1b5add; 
                            height: 34px; 
                            font-size: 18px; 
                            border-radius: 48px; 
                            display: flex; 
                            width: 34px; 
                            align-content: center; 
                            justify-content: center; 

                            @media (max-width:590px) {
                                padding: 9px;
                                padding-top: 9px;
                                padding-bottom: 9px;
                            }
                        }
                    }

                    .react-calendar__tile--active {
                        background: #ffffff;
                        height: 44px;
                        // border-radius: 61px;
                        // max-width: 86px;

                        // margin-left: 12px;
                        abbr {
                            color: #ffffff; 
                            background: #1b5add; 
                            height: 34px; 
                            font-size: 18px; 
                            border-radius: 48px; 
                            display: flex; 
                            width: 34px; 
                            align-content: center; 
                            justify-content: center; 

                            @media (max-width:590px) {
                                padding: 9px;
                                padding-top: 9px;
                                padding-bottom: 9px;
                            }
                        }
                    }
                }
            }
        }
    }
}