.landing-page-footer-main-container {
    position: relative;
    max-width: 100%;
    overflow: hidden;
    max-height: 401px;
    display: flex;
    flex-direction: column;
    background-color: #000000;
    padding: 100px 100px 72px 100px;

    @media (max-width:703px) {
        padding: 32px 24px;
    }

    .landing-page-footer {
        max-width: 100%;
        overflow: hidden;
        max-height: 401px;
        display: flex;
        flex-direction: row;
        background-color: #000000;
        padding-bottom: 100px;

        @media (max-width:703px) {
            padding-bottom: 30px;
        }

        @media (max-width:855px) {
            flex-direction: column;
        }

        .footer-logo-container {
            display: flex;
            height: -moz-max-content;
            height: max-content;
            width: 34.5%;
            @media (max-width:472px) {
               width: 100%;
               height: 100px;
            }
            .landing-page-footer-logo-image {
                height: 48px;
                width: 237px;
            }
        }

        .footer-items-main-container {
            display: flex;
            flex-direction: row;
            width: 65.5%;
            justify-content: space-between;
            padding-top: 12px;
            flex-wrap: wrap;
            .footer-item-container {
                display: flex;
                flex-direction: column;
                align-items: baseline;
                width: 170px;

                .footer-heading {
                    font-family: "Poppins", sans-serif;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 27px;
                    color: #ffffff;
                }

                .footer-items-text {
                    font-family: "Poppins", sans-serif;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 24px;
                    color: #dbdbdb;
                    margin-top: 22px;
                    cursor: pointer;
                }
            }
        }
    }
    hr{
        height: 1px;
        border: 1px solid #979797;
    }
}