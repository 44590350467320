.add-member-main-container {
  max-width: 100%;
  height: 100vh !important;
  width: 100% !important;
  position: relative;
  overflow-y: auto !important; /* Add !important to ensure precedence */
  background-color: #f2f2f2 !important;
}

.add-member-paper-box-image-div {
  margin-top: 52px;
  margin-left: 40%;
  margin-right: 40%;
  max-width: 100%;
  height: auto;
}

/* This CSS applies to all screen sizes */
.add-member-paper-box-image {
  width: 120px;
  height: 120px;
  border-radius: 50%;
}

/* This CSS applies to screens with a maximum width of 768 pixels, which includes iPads */
@media (max-width: 768px) {
  .add-member-paper-box-image {
    width: 100px;
    height: 100px;
  }
}

/* This CSS applies to screens with a maximum width of 600 pixels, which includes mobile devices */
@media (max-width: 600px) {
  .add-member-paper-box-image {
    width: 80px;
    height: 80px;
  }
}

.add-member-subheader-text {
  font-family: "Poppins-Medium", sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 22px;
  color: #3a3a3c;
}

/* Media query for mobile screens */
@media (max-width: 768px) {
  .add-member-paper-box-image-div {
    margin-top: 22px !important;
  }
}

/* Responsive Styles */
@media screen and (max-width: 768px) {
  .add-member-header-container {
    height: auto;
    padding: 16px;
  }

  .logo {
    width: auto;
    height: 36px;
    margin-left: 16px;
    margin-top: 16px;
    flex-shrink: 1;
    flex-grow: 1;
  }

  .add-member-header-right {
    justify-content: center;
    margin-top: 16px;
  }

  .right-image {
    margin-right: 0;
  }
}

.add-member-subheader-container {
  /* background-color: #f9f9f8; */
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 48px;
  margin-left: 130px;
  margin-right: 130px;
}

/* Media query for screens with width less than 768px (mobile devices) */
@media (max-width: 767px) {
  .add-member-subheader-container {
    align-items: flex-start;
    padding: 16px;
    margin-top: 24px;
    margin-left: 30px;
    margin-right: 30px;
  }
}

/* Media query for screens with width between 768px and 1023px (iPad devices) */
@media (min-width: 768px) and (max-width: 1023px) {
  .add-member-subheader-container {
    padding: 16px;
    margin-top: 24px;
    margin-left: 48px;
    margin-right: 48px;
  }
}

/* Style for the paper-box-container div */
.add-member-paper-box-container {
  position: absolute;
  width: 90%;
  display: flex; /* or display: grid; */
  align-items: flex-start; /* Adjust as needed */
  justify-content: space-between;
  border-radius: 16px !important;
  margin-top: 24px;
  margin-left: 130px;
  margin-right: 50px;
}
.add-member-team-member-icon {
  margin-right: 12px;
  flex-direction: row !important;
}

.add-member-right-subheader-container {
  display: flex;
  justify-content: flex-start;
  margin-left: 20px;
  margin-top: 5px;
}

.add-member-right-header-container-nth-child {
  display: flex;
  justify-content: flex-start;
  margin-left: 20px;
  margin-top: 32px;
}
.add-member-right-header-container-services {
  display: flex;
  justify-content: flex-start;
  margin-left: 10px !important;
  margin-top: 20px;
}

.add-member-buttons-container {
  display: flex;
  justify-content: space-between;
  padding: 30px 0 40px;
}
.add-member-subHeader {
  margin-left: 32px;
  white-space: nowrap;
  font-family: "Poppins-Medium", sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #2c2c2e;
}
.add-member-HeaderNote {
  white-space: nowrap;
  font-family: "Poppins-Medium", sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #757575;
  margin-top: 8px;
}

.add-member-paper-box {
  flex: 0 0 calc(50% - 32px);
  height: auto !important;
  border-radius: 16px !important;
  background-color: #ffffff;
  margin: 16px;
  padding-bottom: 2%;
}

@media screen and (max-width: 950px) {
  .add-member-paper-box {
    width: 90%;
    height: auto;
    flex: 0 0 calc(100% - 12px);
    margin: 16px;
  }
}

@media screen and (max-width: 768px) {
  .add-member-paper-box {
    width: 90%;
    flex: 0 0 calc(100% - 12px);
    /* margin: 16px; */
  }
}

/* Responsive Styles */
@media screen and (max-width: 768px) {
  .add-member-paper-box-container {
    flex-wrap: wrap;
    margin-top: 10px;
    margin-left: 16px !important;
  }
}
.add-member-card-field-container {
  margin-top: 30px !important;
  margin-left: 35px;
  margin-right: 35px;
  display: flex;
  flex-direction: column;
}

.add-member-card-field-container.custom-date-styles .MuiInput-formControl:after,
.add-member-card-field-container.custom-date-styles
  .MuiInput-formControl:before {
  display: none;
}

.add-member-card-field-container.custom-date-styles .MuiInputBase-input {
  font: inherit;
  color: currentColor;
  width: 100%;
  border: 0;
  height: 1.1876em;
  margin: 0;
  display: block;
  padding: 18.5px 14px;
  min-width: 0;
  background: none;
  box-sizing: content-box;
  animation-name: mui-auto-fill-cancel;
  letter-spacing: inherit;
  animation-duration: 10ms;
  -webkit-tap-highlight-color: transparent;
  border: 1px solid rgba(0, 0, 0, 0.22);
  border-radius: 5px;
}

@media (max-width: 768px) {
  .add-member-card-field-container {
    margin-left: 10px;
    margin-right: 10px;
  }
}

.add-member-field-container {
  margin-top: 44px !important;
  margin: 0 35px;
  display: flex;
  flex-direction: column;
}

.add-member-field-container-nth-child {
  margin: 0 35px;
  display: flex;
  flex-direction: column;
}

/* Media query for mobile screens */
@media (max-width: 768px) {
  .add-member-field-container {
    margin-top: 22px;
    margin-left: 16px;
    margin-right: 16px;
  }

  .add-member-field-container-nth-child {
    margin-top: 15px !important;
    margin-left: 16px;
    margin-right: 16px;
  }
}

/* Media query for tablet screens */
@media (min-width: 769px) and (max-width: 1024px) {
  .add-member-field-container {
    margin-top: 44px;
    margin-left: 32px;
    margin-right: 32px;
  }
}

.add-member-form-label {
  display: flex;
  font-family: "Poppins-Medium", sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #2c2c2e;
}

.add-member-form-input-style {
  box-sizing: border-box;
  width: 100%;
  height: 54px;
  margin-top: 10px;
  margin-bottom: 5px;
  border-radius: 8px !important;
  font-family: "Poppins-Medium", sans-serif !important;
}
.add-member-card-form-input-style {
  box-sizing: border-box;
  width: 100%;
  height: 54px;
  margin-left: 32px;
  margin-right: 32px;
  margin-top: 12px;
  margin-bottom: 20px;
  border-radius: 8px !important;
  font-family: "Poppins-Medium", sans-serif !important;
}

/* Media query for screens with width less than 768px (mobile devices) */
@media (max-width: 767px) {
  .add-member-card-form-input-style {
    width: 80%;
    margin-left: 34px !important;
    margin-right: 16px;
    margin-top: 8px;
    margin-bottom: 16px;
  }
}

/* Media query for screens with width between 768px and 1023px (iPad devices) */
@media (min-width: 768px) and (max-width: 1023px) {
  .add-member-card-form-input-style {
    width: calc(100% - 48px);
    margin-left: 24px;
    margin-right: 24px;
    margin-top: 10px;
    margin-bottom: 18px;
  }
}

.add-member-country-code-text-style {
  font-family: "Poppins-Medium", sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #2c2c2e;
}

.add-member-Employment-card-container {
  width: 100%;
  height: 245px;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  margin-left: 5px !important;
  margin-top: 24px !important;
  margin-bottom: 30px !important;
  border-radius: 16px !important;
}

/* Media query for screens with width less than 768px (mobile devices) */
@media (max-width: 767px) {
  .add-member-Employment-card-container {
    width: calc(100% - 12px);
    margin-left: 0;
    margin-right: 0;
    margin-top: 12px !important;
    margin-bottom: 10px !important;
    border-radius: 0;
  }
  .add-member-form-custom-select.form-custom-select {
    width: 150px;
  }
  .add-member-Employment-card-container {
    height: 270px;
  }
}

/* Media query for screens with width between 768px and 1023px (iPad devices) */
@media (min-width: 768px) and (max-width: 1023px) {
  .add-member-Employment-card-container {
    width: calc(100% - 32px);
    margin-left: 16px !important;
    margin-right: 16px !important;
    margin-top: 18px !important;
    margin-bottom: 28px !important;
  }
}

.add-member-workingday-card-container {
  margin-top: 24px !important;
  margin-left: 5px !important;
  width: 100%;
  height: auto;
  background-color: #ffffff;
  padding: 20px 35px;
  border-radius: 16px !important;
  margin-bottom: 30px !important;
}

/* Media query for screens with width less than 768px (mobile devices) */
@media (max-width: 767px) {
  .add-member-workingday-card-container {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    margin-top: 12px !important;
    margin-bottom: 20px !important;
    border-radius: 0;
  }
}

/* Media query for screens with width between 768px and 1023px (iPad devices) */
@media (min-width: 768px) and (max-width: 1023px) {
  .add-member-workingday-card-container {
    width: calc(100% - 32px);
    margin-left: 16px !important;
    margin-right: 16px !important;
    margin-top: 18px !important;
    margin-bottom: 28px !important;
  }
}

/* Style for the row-container div */
.add-member-row-container {
  margin-top: 34px;
  display: flex;
  gap: 10px;
}
.add-member-blocktime {
  margin-top: 18px;
}

.add-member-daytime-card-field-container {
  flex: 1;
}

.add-member-field-1 {
  margin-left: 32px;
  margin-right: 24px;
}

.add-member-field-2 {
  margin-right: 32px;
}

.add-member-card-form-sublabel {
  margin-bottom: 16px !important;
  font-family: "Poppins-Medium", sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #2c2c2e;
}

.add-member-card-form-label {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
  font-family: "Poppins-Medium", sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #2c2c2e;
}

.add-member-employment {
  margin-left: 20px;
}

@media (max-width: 768px) {
  .add-member-card-form-label {
    font-size: 16px;
    line-height: 20px;
  }
  .add-member-employment {
    margin-left: 40px;
  }
}

.add-member-daytime-form-input-style {
  display: block;
  width: 90%;
  min-width: 170px;
  height: 54px;
  padding: 8px;
  font-size: 14px;
  border: 1px solid #cccccc;
  border-radius: 4px;
  background-color: #ffffff;
  margin-top: 5px;
  font-family: "Poppins-Medium", sans-serif !important;
}

/* Media query for screens with width less than 768px (mobile devices) */
@media (max-width: 767px) {
  .add-member-daytime-form-input-style {
    width: 100%;
    min-width: auto;
    font-size: 16px;
    margin-top: 8px;
  }
}

/* Media query for screens with width between 768px and 1023px (iPad devices) */
@media (min-width: 768px) and (max-width: 1023px) {
  .add-member-daytime-form-input-style {
    width: 90%;
    min-width: auto;
    font-size: 15px;
    margin-top: 6px;
  }
}

.add-member-workingday-card-content {
  margin-top: 24;
  display: flex;
  justify-content: center;
  align-items: center;
}

.add-member-myCustomButton {
  border: 1px solid rgba(0, 0, 0, 0.12) !important;
  border-radius: 24px !important;
  width: 150px;
  height: 44px;
  font-size: 14px !important;
  font-weight: 500;
  text-transform: none !important;
  font-style: normal;
  line-height: 21px !important;
  font-family: "Poppins-Medium", sans-serif !important;
}
.add-member-weekDays-map-container {
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: 32px;
}

.add-member-employess-weekdays-button {
  text-transform: none !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 27px !important;
}

/* Media query for screens with width less than 768px (mobile devices) */
@media (max-width: 767px) {
  .add-member-weekDays-map-container {
    margin-top: 8px;
    margin-left: 16px;
  }

  .add-member-employess-weekdays-button {
    font-size: 16px !important;
    min-width: 100px !important;
  }
}

/* Media query for screens with width between 768px and 1023px (iPad devices) */
@media (min-width: 768px) and (max-width: 1023px) {
  .add-member-weekDays-map-container {
    margin-top: 12px;
    margin-left: 24px;
  }

  .add-member-employess-weekdays-button {
    font-size: 17px !important;
    min-width: 110px !important;
  }
}

/* Default styles */
.add-member-allService-form-input-style {
  box-sizing: border-box;
  width: 100%;
  height: 54px;
  margin-top: 12px;
  margin-bottom: 20px;
  border-radius: 8px;
  background: #ffffff !important;
  font-family: "Poppins-Medium", sans-serif !important;
}

/* Media query for screens with a maximum width of 768px, which includes tablets and small laptops */
@media (max-width: 768px) {
  .add-member-allService-form-input-style {
    width: 100%;
    margin-right: 0;
    margin-bottom: 16px;
  }
}

/* Media query for screens with a maximum width of 480px, which includes most mobile devices */
@media (max-width: 480px) {
  .add-member-allService-form-input-style {
    width: 100%;
    height: 48px;
    margin-right: 0;
    margin-bottom: 12px;
    font-size: 14px;
  }
}

.add-member-container {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.add-member-label-left {
  font-family: "Poppins-Medium", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 16px;
  color: #2c2c2e;
}

.add-member-label-right {
  margin-left: auto;
}

.add-member-search-service-input-style {
  margin: auto !important;
  width: 100%;
  height: 54px !important;
  color: #757575;
  border-radius: 24px !important;
  margin-left: 30px !important;
  font-family: "Poppins-Medium", sans-serif !important;
  font-weight: 500;
  font-size: 16px;
}

.add-member-upload__image-wrapper svg {
  width: 26px;
  height: 26px;
  filter: invert(0.5);
}

.add-member-upload__image-wrapper {
  background: #eee9e9;
  width: 120px;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  vertical-align: middle;
  object-fit: contain !important;
  overflow: hidden;
}
.contain-image {
  width: 120px;
  height: 120px;
  border-radius: 100%;
}

.add-member-profile-form-sub-header {
  font-family: "Poppins-Medium", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 0;
}

.add-member-hours-form {
  display: flex;
  margin-top: 40px !important;
  margin: auto;
}
.add-member-hours-to {
  display: flex;
  margin-top: 40px !important;
  margin: auto;
}

.add-member-form-custom-select.large-input select {
  border: 1px solid rgb(117, 117, 117);
  border-radius: 8px;
  margin: 0;
  direction: inherit;
}

.add-member-form-custom-select.large-input .arabic-dropdown-image,
.custom-select-profile .arabic-dropdown-image,
.custom-select-team .arabic-dropdown-image,
.form-custom-select .arabic-dropdown-image,
.add-member-form-custom-select .arabic-dropdown-image {
  position: absolute;
  top: 50%;
  left: 10px;
  margin-right: 5px;
  transform: translateY(-50%);
  width: 24px;
  height: 24px;
  right: auto;
}

.add-member-form-custom-select.large-input .dropdown-image,
.custom-select-profile .dropdown-image,
.custom-select-team .dropdown-image,
.form-custom-select .dropdown-image,
.add-member-form-custom-select .dropdown-image {
  position: absolute;
  top: 50%;
  right: 10px;
  margin-right: 5px;
  transform: translateY(-50%);
  width: 24px;
  height: 24px;
}

.add-member-form-custom-select.large-input {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 54px;
  margin-top: 15px;
  font-family: "Poppins-Medium", sans-serif !important;
  margin-bottom: 25px;
}

.add-member-servicelist-list-container {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  height: 385px;
  width: 99%;
  scrollbar-color: #757575 #e5e5ea !important;
  scrollbar-width: thin;
}

.add-member-servicelist-list-container::-webkit-scrollbar-track {
  border-radius: 3px !important;
}

.add-member-servicelist-main-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

.add-member-servicelist-detail-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  margin-bottom: 25px !important;
}

.add-member-employee-service-note {
  font-family: "Poppins-Medium", sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #636366;
}

.add-member-employee-service-name-text {
  font-family: "Poppins-Medium", sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: #2c2c2e;
  width: 80%;
  margin-top: 11px;
  margin-bottom: 11px;
}

.add-member-error-message {
  font-family: "Poppins-Medium", sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #ff453a;
  margin-top: 5px;
}

.add-member-service-modal {
  position: absolute !important;
  background-color: #ffffff !important;
  width: 40%;
  height: 80%;
  margin-left: 30%;
  margin-top: 5%;
  border-radius: 24px;
  border: 1px solid #48484a;
  display: flex;
  overflow: auto;
  z-index: auto !important;
}

.arabic-add-member-service-modal {
  position: absolute !important;
  background-color: #ffffff !important;
  width: 40%;
  height: 80%;
  margin-right: 30%;
  margin-top: 5%;
  border-radius: 24px;
  border: 1px solid #48484a;
  display: flex;
  overflow: auto;
  z-index: auto !important;
}

.add-member-cancel-button {
  width: 30% !important;
  height: 56px !important;
  color: #ff453a !important;
  background-color: #ffffff !important;
  font-family: "Poppins-Medium", sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 18px !important;
  text-align: center !important;
  text-transform: none !important;
  border-radius: 28px !important;
  border: 1px solid #ff453a !important;
  margin-left: 70px !important;
  margin-top: 44px !important;
  margin-bottom: 44px !important;
}

.add-member-delete-button {
  width: 30% !important;
  height: 56px !important;
  color: #ffffff !important;
  background-color: #ff453a !important;
  font-family: "Poppins-Medium", sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 18px !important;
  text-align: center !important;
  text-transform: none !important;
  border-radius: 28px !important;
  margin-left: 70px !important;
  /* margin-top: 44px !important; */
  margin-bottom: 44px !important;
}

.add-member-save-button {
  width: 30% !important;
  height: 56px !important;
  color: #ffffff !important;
  background-color: #1e5fea !important;
  font-family: "Poppins-Medium", sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 18px !important;
  text-align: center !important;
  text-transform: none !important;
  border-radius: 28px !important;
  border: 1px solid #2c2c2e !important;
  margin-left: 70px !important;
  margin-top: 44px !important;
  margin-bottom: 44px !important;
}

.add-member-service-modal-container {
  margin-top: 50px;
  margin-left: 10%;
  margin-bottom: 50px !important;
  width: 80%;
}

.arabic-add-member-service-modal-container {
  margin-top: 50px;
  margin-right: 10%;
  margin-bottom: 50px !important;
  width: 80%;
}

.add-member-service-category {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.add-member-service-header {
  font-family: "Poppins-Medium", sans-serif !important;
  margin-top: 10px;
  height: 54px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #1e5fea;
  border-radius: 24px;
  color: #ffffff;
  padding: 0 20px;
  margin-bottom: 30px;
  text-transform: none !important;
}

.add-member-service-header .MuiButton-label {
  font-family: "Poppins-Medium", sans-serif !important;
  text-transform: none !important;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}

.delete-member-modal {
  width: 392px;
  height: 464px;
  left: 35% !important;
  top: 25% !important;
  right: 35% !important;
}

.add-member-buttons-container #saveButton,
.add-member-buttons-container #cancelButton,
.add-member-buttons-container #deleteButton,
.responsiveBtn {
  margin: 0 10px 0 !important;
  width: calc(50% - 10px) !important;
}

.add-member-buttons-container #saveButton,
.rgtBtn {
  margin: 0 0 0 10px !important;
}

.add-member-manage-services-item-container {
  display: flex;
  justify-content: space-between;
  align-content: center;
  margin: 0 20px 20px;
}

.add-member-user-details {
  display: flex;
  flex-direction: column;
}

.del-modal-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
  background: #fff;
  border-radius: 30px;
}
.del-modal-container > * {
  margin: 12px 0 !important;
  text-align: center;
  font-size: 20px;
}

.del-modal-container > button {
  width: 100% !important;
}

.service-checkbox-checked {
  color: #ffffff;
  background-color: #1e5fea;
}

.service-header {
  font-family: "Poppins-Medium", sans-serif !important;
  font-weight: 500;
  font-size: 24px;
}

.search-icon {
  width: 16px !important;
  height: 16px !important;
  margin-left: 25px;
}

/* .MuiOutlinedInput-root {
  border-radius: 25px !important;
} */

.service-category {
  background-color: #3d40c3;
  border-radius: 20px;
  padding: 5px 10px 5px 10px;
  margin-left: 10px;
  font-size: 12px;
  color: #fff;
}

.add-member-input-container-item-time {
  justify-content: center;
}
