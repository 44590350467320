.language-switcher-main-div {
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  @media (max-width: 495px) {
    margin-left: 0px;
  }

  .main-language-div {
    max-width: 100%;
    border-radius: 16px;
    height: max-content;
    padding: 50px;
    background: #ffffff;

    div {
      border-radius: 9px;
      margin-bottom: 15px;
      background-blend-mode: normal;
      padding: 20px;
      cursor: pointer;

      h1 {
        font-size: 20px;
        font-weight: 400;
      }

      p {
        font-size: 16px;
        font-weight: 400;
      }
    }
  }
}
