.manage-team-main-container {
  position: relative;
  overflow: auto;
  background-color: #f2f2f2;
}

.manage-team-heading-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.manage-team-header-text {
  font-family: "Poppins-Medium", sans-serif;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #48484a;
  margin-bottom: 21px;
}

.manage-team-staff-main-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 643px;
  height: 422px;
  background-color: #ffffff;
  border-radius: 16px;
  margin-top: 24px;
}

.manage-team-input-container {
  display: flex;
  flex-direction: column;
  width: 85%;
}

.manage-team-content-container {
  position: absolute;
  width: 700px;
  top: 115px;
  left: 580px;
  height: 83vh;
  overflow-y: scroll;
  scrollbar-width: none !important;
}

.arabic-manage-team-content-container {
  position: absolute;
  width: 700px;
  top: 115px;
  right: 580px;
  height: 83vh;
  overflow-y: scroll;
  scrollbar-width: none !important;
}

.manage-team-content-container::-webkit-scrollbar,
.arabic-manage-team-content-container::-webkit-scrollbar {
  display: none !important;
}

@media (max-width: 1292px) {
  .manage-team-content-container {
    left: 483px;
    width: calc(100% - 500px);
  }
  .arabic-manage-team-content-container {
    right: 483px;
    width: calc(100% - 500px);
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .manage-team-content-container {
    left: 418px;
    width: calc(100% - 430px);
  }
  .arabic-manage-team-content-container {
    right: 418px;
    width: calc(100% - 430px);
  }
}

@media (max-width: 840px) {
  .manage-team-content-container {
    left: 127px;
    width: calc(100% - 220px);
    top: 715px;
    margin-top: 10%;
  }
  .arabic-manage-team-content-container {
    right: 127px;
    width: calc(100% - 220px);
    top: 715px;
  }
}

@media screen and (max-width: 767px) {
  .manage-team-content-container {
    left: 50px;
    width: 85%;
    top: 750px;
  }
  .arabic-manage-team-content-container {
    right: 50px;
    width: 85%;
    top: 750px;
  }
}

.manage-team-staff-list-container {
  display: flex;
  width: 95%;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-top: 32px;
}

.manage-team-card-container {
  padding: 48px 45px;
  width: 100%;
  height: auto;
  border-radius: 16px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.manage-team-heading {
  font-family: "Poppins-Medium", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #48484a;
  width: 75%;
}

.manage-team-input-search {
  margin: auto !important;
  box-sizing: border-box;
  width: 100%;
  height: 54px !important;
  color: #757575;
  border-radius: 25px !important;
  font-family: "Poppins-Medium", sans-serif !important;
  font-weight: 500;
  font-size: 16px;
}

.manage-team-input-search .MuiOutlinedInput-root {
  border-radius: 25px !important;
}

.search-icon {
  width: 20px !important;
  height: 20px !important;
  margin-left: 15px;
}

.manage-services-wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 35px;
  width: 100%;
}

.manage-services-item-container {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}

.user-image {
  width: 56px;
  height: 56px;
  border-radius: 100px;
}
.user-details {
  display: flex;
  justify-content: center;
  font-family: "Poppins-Medium", sans-serif;
  margin: 0 16px;
  flex-direction: column;
  flex-grow: 1;
}

.user-name {
  font-weight: 500;
  font-size: 20px;
  line-height: 18px;
  color: #2c2c2e;
  font-family: "Poppins-Medium", sans-serif;
}
.user-email {
  font-weight: 400;
  font-size: 16px;
  margin-top: 12px;
  line-height: 16px;
  color: #757575;
  font-family: "Poppins-Medium", sans-serif;
}

.add-member-button {
  width: 75% !important;
  height: 56px !important;
  color: #ffffff !important;
  background-color: #1e5fea !important;
  font-family: "Poppins-Medium", sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 18px !important;
  text-align: center !important;
  text-transform: none !important;
  border-radius: 28px !important;
  border: 1px solid #2c2c2e !important;
 margin: auto !important;
}

.add-member-button img {
  margin-right: 8px !important;
  width: 23px;
  height: 23px;
}
