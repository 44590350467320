.profile-page-main-container {
  max-width: 100%;
  width: 100% !important;
  position: relative;
  height: 2512px;
  overflow-y: auto;
  background-color: #f9f9f8 !important;
}

.root-page {
  max-width: 100% !important;
  overflow: hidden;
  padding: 60px 100px 32px 150px;
  /* margin-top: 40px; */

  background-color: #ffffff;
  /* min-width: 275; */
  font-family: "Poppins", sans-serif !important;
}
.fontfamily-profile {
  font-family: "Poppins-Medium", sans-serif !important;
}
.profile-note-item-text {
  font-family: "Poppins", sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 27px;
  color: #2c2c2e;
  cursor: pointer;
  padding: 60px 10px;
}
@media (max-width: 1024px) {
  .profile-note-item-text {
    font-size: 14px;
    line-height: 24px;
    padding: 40px 10px;
    margin-left: 10%;
  }
}
/* Media query for mobile devices */
@media (max-width: 767px) {
  .profile-note-item-text {
    font-size: 14px;
    line-height: 24px;
    padding: 30px 10px;
    margin-left: 40px !important;
  }
}
.profile-main-container-text {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  position: absolute;
}
@media (max-width: 768px) {
  .profile-main-container-text {
    position: relative;
    justify-content: flex-start;
    padding: 10px;
  }
}
@media (max-width: 480px) {
  .location-main-container-text {
    padding: 5px; /* Adjust the padding for even smaller devices */
  }
}
.profile-papar-container {
  display: flex;
  width: 100%;
  max-width: 590px !important;
  height: 100%;
  border-radius: 16px;
  margin-top: 40px !important;
  margin-right: 20px;
  margin-bottom: 40px;
}

/* Media query for screens with a maximum width of 768 pixels */
@media (max-width: 768px) {
  .profile-papar-container {
    flex-direction: column;
    margin-right: 0;
  }
}

.input-container {
  display: flex;
  /* position: absolute; */
  margin-top: 15px;
  margin-left: 40px;
  margin-right: 40px;
  flex-direction: column;
}

.input-container-left {
  display: flex;
  /* position: absolute; */
  margin-top: 20px !important;
  margin-right: 40px;
  margin-bottom: 20px !important;
  flex-direction: column;
}

@media (max-width: 768px) {
  .input-container-left {
    /* position: static; */
    margin-right: 20px !important;
    margin-bottom: 10px !important;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .input-container-left {
    /* position: static; */
    margin-right: 0;
    margin-bottom: 15px !important;
  }
}

.input-container-item {
  display: flex;
  position: relative;
  margin-top: 40px;
}
.input-container-item-time {
  display: flex;
  direction: inherit;
}
/* Responsive styles for screens smaller than 768px */
@media (max-width: 767px) {
  .input-container-item-time {
    flex-direction: column;
  }
}
.working-hours-lable {
  /* Default styles */
  margin-left: 320px;
  position: absolute;
  margin-bottom: 50px;
  margin-top: 30px;
}

/* Responsive styles for screens smaller than 768px */
@media (max-width: 767px) {
  .working-hours-lable {
    position: relative !important;
    margin-left: 60px;
    margin-bottom: 8px !important;
    margin-top: 15px;
  }
}

.left-side-label {
  flex-direction: column;
  display: flex;
  margin-bottom: 40px;
  margin-left: 10px !important;
}

@media (max-width: 768px) {
  .left-side-label {
    margin-left: 0 !important;
    margin-bottom: 20px;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .left-side-label {
    margin-left: 0 !important;
    margin-bottom: 30px;
  }
}
.profile-left-side-body {
  flex-direction: column;
  display: flex;
  margin-bottom: 20px;
  margin-left: 10px !important;
}
@media (max-width: 768px) {
  .profile-left-side-body {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }
}

.profile-left-side-label {
  flex-direction: column;
  display: flex;
  margin-left: 10px !important;
}

@media (max-width: 768px) {
  .profile-left-side-label {
    margin-left: 40px !important;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .profile-left-side-label {
    margin-left: 0 !important;
    margin-bottom: 15px;
  }
}

.right-side-label {
  flex-direction: column;
  display: flex;
  margin-bottom: 40px;
}
.profile-header-item-text {
  font-family: "Poppins-Medium", sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #2c2c2e;
  cursor: pointer;
  padding: 60px 10px;
}
/* Media query for mobile devices */
@media (max-width: 767px) {
  .profile-header-item-text {
    font-size: 20px;
    line-height: 30px;
    padding: 30px 10px;
    margin-left: 40px !important;
  }
}
/* Media query for iPad */
@media (max-width: 1024px) {
  .profile-header-item-text {
    font-size: 20px;
    line-height: 30px;
    padding: 40px 10px;
    margin-left: 10%;
  }
}
.sub-header-item-text {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 19px;
  line-height: 27px;
  color: #2c2c2e;
  cursor: pointer;
  /* margin-bottom: 15px; */
}
.profile-form-sub-header {
  font-family: "Poppins-Medium", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
}
.add-team-member-block-time {
  font-family: "Poppins-Medium", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  margin-right: 69%;
  margin-top: 10px;
}
.add-team-member-week-to {
  font-family: "Poppins-Medium", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  margin-right: 76%;
}
@media only screen and (max-width: 600px) {
  .add-team-member-week-to {
    margin-right: 55%;
  }
  .add-team-member-block-time {
    margin-right: 50%;
  }
}

.form-label {
  font-family: "Poppins-Medium", sans-serif !important;
  display: flex;
  /* position: relative; */
  /* margin-right: 40px; */
  color: #2c2c2e;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
}

@media (max-width: 768px) {
  .form-label {
    margin-right: 0;
    margin-bottom: 10px;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .form-label {
    margin-right: 0;
    margin-bottom: 15px;
  }
}
.dialog-label {
  font-family: "Poppins-Medium", sans-serif;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 10px;
  /* position: absolute;  */
}
.profile-row-button {
  width: 156px !important;
  height: 54px !important;
  border-radius: 25px !important;
  font-family: "Poppins-Medium", sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 18px !important;
  text-align: center !important;
  text-transform: none !important;
}
.profile-bank-dialog {
  display: flex;
  flex-direction: row !important;
}
.profile-button-container {
  display: flex;
  margin-left: 10px;
  /* margin-right: 40px; */
  margin-bottom: 20px;
}
.form-label-team {
  font-family: "Poppins-Medium", sans-serif !important;
  font-weight: 500;
  line-height: 27px;
  display: flex;
  margin-right: 40px;
  color: #2c2c2e;
  font-size: 14px;
  white-space: "nowrap";
}
/* Responsive styles for screens smaller than 768px */
@media (max-width: 767px) {
  .form-label-team {
    margin-right: 10px;
    font-size: 12px !important;
    white-space: "break-word" !important;
  }
}
.dialog-input-style {
  font-family: "Poppins-Medium", sans-serif !important;
}
.form-lable-note {
  display: flex;
  height: 16px;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #757575;
}
.form-label-dropdown {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  line-height: 22px;
  margin-left: 20px;
}

.profile-root-card {
  width: 528px;
  height: 300px;
  margin-top: 10px;
  left: 152px;
  top: 325px;
  border: 0;
  border-top: 0;
  border-radius: 0;
}

@media (max-width: 768px) {
  .profile-root-card {
    width: 100%;
    height: 242px !important;
    margin-top: 10px;
    left: 0;
    top: 0;
  }
}

.setup-input-image {
  width: 155px;
  height: 236px !important;
  margin: 5px;
  border-radius: 8px;
  object-fit: cover;
}
/* Responsive CSS */
@media (max-width: 768px) {
  .setup-input-image {
    width: 90px !important;
    height: 200px !important;
    margin: 5px !important;
    border-radius: 8px;
    object-fit: cover;
  }
}
.setup-image-list {
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
}
@media (max-width: 768px) {
  .setup-image-list {
    margin: 3px;
  }
}
.setup-remove-image-icon {
  margin-left: 100px !important;
  margin-top: 5px !important;
  overflow: hidden !important;
  position: absolute !important;
}
/* Responsive CSS */
@media (max-width: 768px) {
  .setup-remove-image-icon {
    margin-left: 50px !important;
  }
}

.setup-input-image-map {
  width: 145px;
  height: 113px;
  margin: 5px;
  border-radius: 8px;
  object-fit: cover;
}
@media (max-width: 768px) {
  .setup-input-image-map {
    width: 90px !important;
    max-width: 117px !important;
    height: 95px !important;
    border-radius: 8px;
    object-fit: cover;
    margin: 3px !important;
  }
}
.setup-image-div {
  display: flex;
  margin-top: 30px;
  margin-bottom: 15px !important;
  margin-left: 27px !important;
}
@media (max-width: 768px) {
  .setup-image-div {
    margin-top: 15px !important;
    margin-left: 15px !important;
    margin-bottom: 2px !important;
  }
}
.setup-add-image-icon {
  margin-top: 100px !important;
  margin-left: 200px !important;
}

@media (max-width: 768px) {
  .setup-add-image-icon {
    margin-top: 30% !important;
    margin-left: 40% !important;
  }
}

.input-image {
  border-radius: 8px !important;
  min-width: 170px;
  min-height: 105px;
}
.profile-image-center {
  margin-top: 50px;
  padding: 20px 200px;
}
.imageList {
  width: 540px !important;
  height: 300px !important;
  border-radius: 8px !important;
}
.profile-font {
  font-family: "Poppins-Medium", sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #2c2c2e;
  margin-left: 50 !important;
  flex-direction: row;
}
@media (max-width: 768px) {
  .profile-font {
    margin-left: 30px !important;
  }
}
.options-lable {
  color: #2c2c2e;
  margin-top: 40px !important;
  margin-left: 50px !important;
}
@media (max-width: 768px) {
  .options-lable {
    margin-left: 30px !important;
  }
}
.form-input-style {
  font-family: "Poppins-Medium", sans-serif !important;
  display: flex;
  width: 536px;
  height: 54px;
  box-sizing: border-box;
  border: 1px solid #757575 !important;
  border-radius: 8px !important;
  margin-top: 10px;
  /* margin-right: 15px; */
  outline: none;
  box-shadow: none !important;
}

.form-input-style-bank {
  border: 1px solid #757575;
  width: 492px;
  height: 54px;
  border-radius: 8px !important;
  font-family: "Poppins", sans-serif !important;
  margin-top: 10px;
  margin-left: 50px !important;
  margin-right: 15px;
}

@media (max-width: 768px) {
  .form-input-style-bank {
    width: 100% !important;
    margin-left: 30px !important;
    margin-right: 30px !important;
    margin-bottom: 10px;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .form-input-style-bank {
    width: 100%;
    margin-left: 30px;
    margin-right: 30px;
    margin-bottom: 15px;
  }
}

@media (max-width: 768px) {
  .form-input-style {
    width: 100%;
    margin-right: 0;
  }
}
.form-input-style-contact-number {
  width: 536px;
  height: 54px;
  margin-top: 10px;
  /* margin-right: 15px; */
  border: 1px solid #757575;
  border-radius: 8px !important;
  font-family: "Poppins-Medium", sans-serif !important;
}

@media (max-width: 768px) {
  .form-input-style-contact-number {
    width: 100%;
    margin-right: 0;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .form-input-style-contact-number {
    width: 100%;
    margin-right: 0;
    margin-bottom: 10px;
  }
}

.form-select-input-style {
  margin-top: 15px;
  margin-bottom: 20px;
  margin-right: 15px;
  position: relative;
}

@media (max-width: 768px) {
  .form-select-input-style {
    position: static;
    margin-right: 0;
    margin-bottom: 10px;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .form-select-input-style {
    position: static;
    margin-right: 0;
    margin-bottom: 15px;
  }
}

.country-code-text-style {
  font-family: "Poppins-Medium", sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #2c2c2e;
}

.payment-options-lable {
  font-style: "normal";
  font-weight: 500;
  font-size: 20;
  line-height: "30px";
  margin-top: 3px;
  font-family: "Poppins-Medium", sans-serif;
}
.payment-options-checkbox {
  margin-top: 22px;
  margin-left: 40px;
  flex-direction: column !important;
}
@media (max-width: 768px) {
  .payment-options-checkbox {
    margin-left: 30px;
  }
}
.input-container-button {
  display: flex;
  justify-content: center; /* Horizontally center the content */
  margin-top: 20px;
  margin-left: 40px !important;
}
.btn1 {
  position: relative;
  width: 104px;
  height: 41px;
  text-transform: none !important;
  border-radius: 25px !important;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  margin-right: 10px;
  margin-left: 20px;
}
.input-dropdown {
  display: flex;
  margin-top: 40px;
  margin-left: 60px;
}
.form-working-days {
  display: flex;
  margin-top: 20px;
  margin-left: 60px;
}
.form-working-days-label {
  display: flex;
  flex-direction: row !important;
  flex-wrap: wrap !important;
  margin-top: 15px;
  /* margin-left: 60px; */
  /* align-items: "center"; */
}
.form-working-days-label-button {
  display: flex;
  margin-left: 35px !important;
  flex-wrap: wrap !important;
  flex-direction: row !important;
}
.form-working-days-label-day {
  display: flex;
  flex-wrap: wrap !important;
  flex-direction: row !important;
  justify-content: flex-start;
  margin-left: 45px;
}
@media only screen and (max-width: 600px) {
  .form-working-days-label-day {
    margin-left: 65px;
  }
}
.profile-from-button {
  text-transform: none !important;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  min-width: 120px;
}
option {
  color: inherit !important;
}
.team-icon {
  display: flex;
  position: absolute;
  margin-left: 60px;
  margin-top: 5px;
  margin-right: 20px;
}

/* Responsive styles for screens smaller than 768px */
@media (max-width: 767px) {
  .team-icon {
    position: relative !important;
    margin-left: 30px !important;
  }
}
.team-members {
  display: flex;
  margin-top: 5px;
  margin-left: 10px;
  flex-direction: column;
}
.hours-form {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px !important;
  margin-top: 40px;
  margin-left: 40px;
}
/* Responsive styles */
@media (max-width: 767px) {
  .hours-form {
    flex-direction: column;
    margin-right: 40px;
  }

  .add-member-input-container-item-time .hours-form {
    flex-direction: column;
    margin-right: auto !important;
  }
}
.hours-to {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px !important;
  margin-top: 40px;
  margin-left: 40px;
}

/* Responsive styles */
@media (max-width: 767px) {
  .hours-to {
    flex-direction: column;
    margin-top: 0 !important;
    margin-left: 60px !important;
    margin-right: 40px !important;
  }

  .add-member-input-container-item-time .hours-to {
    flex-direction: column;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

.profile-next-Step-button {
  width: 492px !important;
  height: 56px !important;
  background-color: #1e5fea !important;
  margin: 10px 20px;
  margin-left: 40px;
  color: #ffffff !important;
  border-radius: 25px !important;
  font-family: "Poppins", sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 18px !important;
  text-align: center !important;
  text-transform: none !important;
}
/* Responsive styles for screens smaller than 768px */
@media (max-width: 767px) {
  .profile-next-Step-button {
    width: 100% !important;
    margin-right: 40px;
  }
}
.overlay {
  position: absolute;
  top: 8px;
  right: 16px;
  font-size: 18px;
  top: 0;
  right: 0;
  /* background: rgb(0, 0, 0); */
  background: rgba(0, 0, 0, 0);
  /* Black see-through */
  color: #f1f1f1;
  width: 100%;
  transition: 0.5s ease;
  opacity: 0;
  font-size: 20px;
  padding: 20px;
  text-align: right;
}

.imageList:hover .overlay {
  opacity: 1;
}

.react-switch-checkbox {
  height: 0;
  width: 0;
  visibility: hidden;
}

.react-switch-label {
  display: flex;
  cursor: pointer;
  margin-top: 10px;
  position: relative;
  width: 42px;
  height: 24px;
}

.react-switch-label .react-switch-button {
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  width: 20px;
  height: 20px;
  border-radius: 45px;
  transition: 0.2s;
  background: #757575;
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
}

.react-switch-checkbox:checked + .react-switch-label .react-switch-button {
  left: calc(100% - 2px);
  transform: translateX(-100%);
}

.react-switch-label:active .react-switch-button {
  width: 60px;
}

.switch-icon input.react-switch-button:hover {
  background: aquamarine !important;
  margin-top: 20px !important;
}

.profile-error-message-text {
  font-family: "Poppins-Medium", sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #ff453a;
  margin-top: 12px;
}

.profile-bank-details-card {
  margin-top: 30px;
  margin-bottom: 15px;
  width: 586px;
  height: 100% !important;
  background: #ffffff !important;
  border-radius: 16px !important;
  display: flex !important;
}

@media (max-width: 768px) {
  .profile-bank-details-card {
    width: 100%;
    margin-bottom: 20px;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .profile-bank-details-card {
    width: 100%;
    margin-right: 0;
    margin-bottom: 25px;
  }
}

.profile-business-working-hours-card {
  width: 586px;
  height: 100% !important;
  background: #ffffff !important;
  border-radius: 16px !important;
  margin-top: 15px !important;
  margin-bottom: 5px !important;
}
/* Responsive styles for screens smaller than 768px */
@media (max-width: 767px) {
  .profile-business-working-hours-card {
    width: 100%;
    border-radius: 8px;
    margin: 10px 0;
  }
}
.profile-team-members-card {
  margin-top: 20px;
  width: 586px;
  height: 100px !important;
  background: #ffffff !important;
  border-radius: 16px !important;
}

/* Responsive styles for screens smaller than 768px */
@media (max-width: 767px) {
  .profile-team-members-card {
    width: 100% !important;
    border-radius: 8px;
    margin: 10px 0;
  }
}

.custom-select {
  position: relative;
  display: inline-block;
  width: 533px; /* specify width for .custom-select */
  height: 54px;
  border: 1px solid #757575;
  border-radius: 8px;
  overflow: hidden;
  font-family: "Poppins-Medium", sans-serif !important;
}

@media (max-width: 768px) {
  .custom-select {
    width: 100%;
    margin-bottom: 10px;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .custom-select {
    width: 100%;
    margin-bottom: 15px;
  }
}

.form-custom-select {
  position: relative;
  display: inline-block;
  width: 234px; /* specify width for .form-custom-select */
  height: 54px;
  margin-top: 30px;
  font-family: "Poppins-Medium", sans-serif !important;
}

@media (max-width: 767px) {
  .form-custom-select {
    width: 100%;
    min-width: 230px !important;
    display: flex !important;
    margin-top: 15px;
  }
}

.custom-select img,
.form-custom-select img {
  position: absolute;
  top: 50%;
  right: 10px;
  margin-right: 5px;
  transform: translateY(-50%);
  width: 24px;
  height: 24px;
}

.custom-select select,
.form-custom-select select {
  width: 100%;
  height: 100%;
  padding: 10px;
  font-size: 16px;
  border: none;
  outline: none;
  background-color: transparent;
  font-family: "Poppins-Medium", sans-serif !important;
  appearance: none; /* Hide default dropdown arrow icon */
}

.icon-button-bank-details {
  width: 66;
  height: 48;
  cursor: "pointer";
  border-radius: "0%";
}

.bank-account {
  display: flex;
  flex-direction: column;
}

/* Responsive styles for screens smaller than 768px */
@media (max-width: 767px) {
  .bank-account {
    margin-right: 40px !important;
  }
}

.profile-bank-details-dialog {
  height: 335px !important;
  width: 380px !important;
}
/* Responsive styles */
@media screen and (max-width: 480px) {
  .profile-bank-details-dialog {
    height: auto !important;
    width: 100% !important;
    max-width: 380px !important;
  }
}

.profile-bank-details-dialog-icon {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  margin-bottom: 40px !important;
}
