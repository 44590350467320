.payment-options-wrapper * {
  box-sizing: border-box;
}

.payment-section-container {
  display: flex;
  font-family: "Poppins-Medium", sans-serif !important;
  margin-top: 40px;
  margin-left: auto;
  margin-right: auto;
  max-width: 1440px;

  .first-div {
    display: flex;
    flex-wrap: wrap;
    margin: auto;
    margin-bottom: 50px;
    margin-left: 154px;

    @media (max-width:696px) {
      margin-left: 0px;
    }
  }
}

.nocard-div {
  text-align: center;

  h1 {
    color: #2c2c2e;
    font-family: "Poppins-Medium", sans-serif;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    margin-top: 20px;
    margin-bottom: 15px;
  }

  p {
    color: #2c2c2e;
    font-family: "Poppins-Medium", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    margin-bottom: 20px;
  }
}

.payment-options-wrapper {
  display: flex;
  width: 475px;
  height: auto;
  background: #ffffff;
  padding: 40px;
  box-sizing: border-box;
  border-radius: 24px;
  flex-direction: column;

  @media (max-width:480px) {
    width: 100%;
  }
}

.main-options-title {
  color: #2c2c2e;
  font-family: "Poppins-Medium", sans-serif;
  font-size: 16px;
  font-weight: 500;
}

.booking-details-wrapper {
  display: flex;
  width: 503px;
  height: 650px;
  margin-left: 24px;
  background: #ffffff;
  border-radius: 24px;
  padding: 40px;
  box-sizing: border-box;
  justify-content: center;

  @media (max-width:1156px) {
    margin-top: 50px;
    margin-left: 0px;
  }

  @media (max-width:480px) {
    width: 100%;
  }
}

.arabic-booking-details-wrapper {
  display: flex;
  width: 503px;
  height: 650px;
  margin-right: 24px;
  background: #ffffff;
  border-radius: 24px;
  padding: 40px;
  box-sizing: border-box;

  @media (max-width:1156px) {
    margin-top: 50px;
    margin-right: 0px;
  }

  @media (max-width:480px) {
    width: 100%;
  }
}

.payment-options-container {
  background: #ffffff;
  font-family: "Poppins-Medium", sans-serif !important;
  width: 100%;
  margin-right: 20px;
  padding: 20px;
  border-radius: 10px;
}

.payment-summary {
  flex: 1;
  background: #ffffff;
  border-radius: 10px;
  padding: 10px;
}

.cards-container {
  display: flex;
  flex-direction: column;
}

.payment-card-dropdown {
  background: #F9F9F8 !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 88px;
  padding: 0 24px;
  border-radius: 16px !important;
  margin-bottom: 16px;
  cursor: pointer;
}

.payment-card {
  background: #F9F9F8;
  display: flex;
  align-items: center;
  height: 88px;
  padding: 0 16px;
  border-radius: 16px !important;
  margin-bottom: 16px;
  cursor: pointer;
}

.list-checkingpaycard {
  width: 100%;
}

.payment-card :hover {
  background-color: #7073FF;
}

.h2-tag-number {
  color: #48484a;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  font-family: "Poppins-Medium", sans-serif;
}

.h2-name-card {
  color: #2c2c2e;
  font-family: "Poppins-Medium", sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 18px;
}

.pay-at-card {
  background: #F9F9F8 !important;
  display: flex;
  align-items: center;
  height: 88px;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 15px;
  cursor: pointer;
}

.payment-card-details {
  display: flex;
  flex-direction: column;
  margin-left: 16px;
}

.booking-detail {
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  h2 {
    color: #2c2c2e;
    font-family: "Poppins-Medium", sans-serif;
    font-size: 18px;
    font-weight: 500;
    line-height: 18px;
  }
}

.booking-with-detail {
  display: flex;

  p {
    color: #2c2c2e;
    font-family: "Poppins-Medium", sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    margin-top: 10px;
  }
}

.omr-h2 {
  color: #2c2c2e;
  font-family: "Poppins-Medium", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  text-align: right;
}

.summery {
  color: #2c2c2e;
  font-family: "Poppins-Medium", sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
}

.booking-price-details {
  margin-top: 10;
  display: flex;
  justify-content: space-between;

  p {
    color: #2c2c2e;
    font-size: 14px;
    font-family: "Poppins-Medium", sans-serif;
    font-weight: 400;
    line-height: 16px;
  }

  h2 {
    color: #2c2c2e;
    font-family: "Poppins-Medium", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
  }

  h3 {
    color: #2c2c2e;
    font-family: "Poppins-Medium", sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    text-align: right;
  }

  h5 {
    color: #2c2c2e;
    font-family: "Poppins-Medium", sans-serif;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    text-align: right;
  }
}

.pay-by-card-h2 {
  color: #2c2c2e;
  font-family: "Poppins-Medium", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
}

.pay-by-shop-h2 {
  color: #2c2c2e;
  font-family: "Poppins-Medium", sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 18px;
  margin-left: 16px;
}

.booking-schedule {
  margin-top: 20;
  display: flex;
  justify-content: space-between;

  p {
    color: #48484a;
    font-family: 'Poppins-Medium', sans-serif;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
  }

  .time-p {
    color: #2c2c2e;
    font-family: 'Poppins-Medium', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    align-self: center;
    padding-top: 3px;
    direction: initial;
  }
}

.additional-information {
  margin-top: 20px !important;
  width: 100% !important;
  height: 130px;
  background: #F9F9F8;
  border-radius: 8px !important;
  resize: none;
}

.info-div {
  background: #f9f9f8;
  background-blend-mode: normal;
  height: 60px;
  display: flex;
  padding: 10px;
  align-items: baseline;
  height: auto;
  border-radius: 5px;

  img {
    width: 12px;
    height: 12px;
    padding-top: 2px;
  }

  p {
    color: #2c2c2e;
    font-family: "Poppins-Medium", sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    border-radius: 8px;
  }
}

.confirm-and-pay-button {
  width: 100% !important;
  height: 56px !important;
  color: #ffffff !important;
  background-color: #1e5fea !important;
  font-family: "Poppins-Medium", sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 18px !important;
  text-align: center !important;
  text-transform: none !important;
  border-radius: 28px !important;
  border: 1px solid #2c2c2e !important;
  margin-top: 44px !important;
  margin-bottom: 44px !important;
  cursor: pointer;
}