.customer-parent-header-container {
  max-width: 100%;

  .main-header-container {
    padding: 29px 40px;
    max-width: 1440px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    margin: 0 auto;

    .header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      height: auto;

      img {
        width: 15%;
        height: 30%;

        @media (max-width: 753px) {
          width: 45%;
          height: 30%;
        }
      }

      .searchBar_location_navigation {
        display: flex;
        flex-direction: column;
        width: 65%;
        align-items: center;
        justify-content: center;
        @media (max-width: 753px) {
          width: 100%;
          padding-top: 10px;
          padding-bottom: 10px;
        }
        .header-navigation-image {
          width: 54px !important;
          height: 54px !important;
          cursor: pointer !important;
          margin-left: 24px !important;
        }
        .arabic-header-navigation-image {
          width: 54px !important;
          height: 54px !important;
          cursor: pointer !important;
          margin-right: 24px !important;
        }
        .searchBar_with_location {
          width: 75%;
          height: 54px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;

          @media (max-width: 753px) {
            width: 100%;
          }

          .search_bar_div {
            width: 92%;
            border-radius: 50px;
            height: 100%;
            border: 1.5px solid black;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            @media (max-width: 753px) {
              height: 44px;
            }

            .search_bar {
              background: transparent;
              width: 100%;
              border: 0;
              height: 100%;
              padding-left: 25px;
              border-radius: 20%;
              outline: none;
              overflow: hidden;
            }

            .arabic-search_bar {
              width: 100%;
              height: 100%;
              background: transparent;
              border: 0;
              outline: none;
              padding-right: 25px;
              overflow: hidden;
              border-radius: 20%;
            }
            button {
              cursor: pointer;
              background: none !important;
              border-radius: 50%;
              border: 0;
            }
          }
        }
      }

      .subheader {
        display: flex;
        justify-content: space-between;
        width: -moz-max-content;
        width: max-content;
        height: auto;
        align-items: center;
        @media (max-width: 753px) {
          display: none;
        }

        img {
          float: right;
        }

        .paste_image {
          margin-top: 3%;
          margin-right: 10%;
          width: 32px;
          height: 32px;
          cursor: pointer;
        }

        .groupimage {
          cursor: pointer;
          margin-left: 40px;
          width: 54px;
          height: 54px;
          object-fit: cover;
          border-radius: 50%;
        }

        .arabic-groupimage {
          width: 54px;
          margin-right: 40px;
          object-fit: cover;
          height: 54px;
          cursor: pointer;
          border-radius: 50%;
        }
      }

      .subheaderdup {
        justify-content: space-between;
        width: -moz-max-content;
        width: max-content;
        height: auto;
        align-items: center;
        display: none;

        @media (max-width: 753px) {
          display: flex;
        }

        img {
          float: right;
        }

        .paste_image {
          margin-top: 3%;
          margin-right: 10%;
          width: 25px;
          height: 25px;
        }

        .groupimage {
          width: 45px;
          margin-left: 15px;
          height: 45px;
          cursor: pointer;
          border-radius: 50%;
          object-fit: cover;
        }

        .arabic-groupimage {
          height: 45px;
          width: 45px;
          margin-right: 15px;
          cursor: pointer;
          border-radius: 50%;
          object-fit: cover;
        }
      }
    }

    .location_with_text {
      width: 41%;
      display: flex;
      flex-direction: row;
      margin: auto;
      margin-top: 12px;
      @media (max-width: 753px) {
        width: 100%;
      }
    }
  }
}

.search-icon-img {
  width: 54px !important;
  height: 54px !important;

  @media (max-width: 753px) {
    width: 50px !important;
    height: 50px !important;
  }
  @media (max-width: 470px) {
    width: 45px !important;
    height: 44px !important;
  }
}

.content-modal-enable {
  width: 427px;
  height: 450px;
  border-radius: 24px;
  background: #ffffff;
  background-blend-mode: normal;
  text-align: center;
  padding: 32px;
  overflow: auto;
  max-width: 100%;

  // @media (max-width: 1045px) {
  //   width: 50%;
  // }

  @media (max-width: 465px) {
    width: 100%;
  }

  .enable_location_btn {
    cursor: pointer;
    border-radius: 23px;
    background: #1e5fea;
    background-blend-mode: normal;
    color: #ffffff;
    font-family: Poppins, "Gotham", "Courier New", Courier, monospace;
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    height: 46px;
    width: 100%;
    margin: 10;
    text-transform: capitalize;
  }

  h4 {
    margin-top: 20px;
    color: #2c2c2e;
    font-family: Poppins, "Gotham", "Courier New", Courier, monospace;
    font-size: 15px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
  }

  p {
    color: #2c2c2e;
    font-family: Poppins, "Gotham", "Courier New", Courier, monospace;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    margin-top: 10px;
  }

  h5 {
    color: #2c2c2e;
    font-family: Poppins, "Gotham", "Courier New", Courier, monospace;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    cursor: pointer;
  }
}
