.employee-calendar-and-buttons-home-screen-sub-container {
    display: flex;
    width: 55%;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly !important;
}

.employee-business-home-screen-date-container {
    display: flex;
    flex-direction: row;
    align-items: center !important;
    background-color: #ffffff;
    border: 1px solid rgba(151, 151, 151, 0.205365);
    border-radius: 24px;
    width: 278px;
    height: 48px;
    justify-content: center !important;
  }

  .add-client-paper-box {
    width: 1252px;
    height: 881px;
    background: #ffffff;
    border-radius: 16px;
  }
  
  @media (max-width: 768px) {
    .add-client-paper-box {
      width: 100%;
      height: auto;
      max-width: 600px;
      padding: 20px;
      box-sizing: border-box;
    }
  }
  
  @media (max-width: 480px) {
    .add-client-paper-box {
      max-width: 300px;
    }
  }

  .centered-label {
    margin-top: 30px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 24px;
    font-family: "Poppins-Medium", sans-serif !important;
  }
  
  @media (max-width: 768px) {
    .centered-label {
      flex-direction: column;
      text-align: center;
    }
  }

  .addclient-input-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 64px;
  }
  .second{
    margin-top: 32px !important;
  }
  
  .addclient-input-row {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }
  
  .addclient-dialog-label {
    margin-bottom: 8px;
    font-weight: 500;
    font-size: 18px;
    font-family: "Poppins-Medium", sans-serif !important;
  }
  
  .addclient-dialog-input-style {
    width: 476px;
    height: 54px;
    border-radius: 8px !important;
    border: 1px solid #757575;
    font-family: "Poppins-Medium", sans-serif !important;
  }
  
  @media (max-width: 768px) {
    .addclient-dialog-input-style {
      width: 100%;
    }
    
    .addclient-dialog-label {
      margin-left: 127px;
    }
  }
  
  @media (max-width: 480px) {
    .addclient-dialog-label {
      margin-left: 0;
      text-align: center;
    }
  }

  .addclient-custom-select-team {
    position: relative;
    width: 476px;
    height: 54px;
    border: 1px solid #757575;
    border-radius: 8px;
    overflow: hidden;
    font-family: "Poppins-Medium", sans-serif !important;
  }
  
  .addclient-custom-select-team img {
    position: absolute;
    top: 50%;
    right: 10px;
    margin-right: 5px;
    transform: translateY(-50%);
    width: 24px;
    height: 24px;
  }
  
  .addclient-custom-select-team select {
    width: 100%;
    height: 100%;
    padding: 10px;
    font-size: 16px;
    border: none;
    outline: none;
    background-color: transparent;
    font-family: "Poppins-Medium", sans-serif !important;
    appearance: none;
  }
  
  @media (max-width: 768px) {
    .addclient-custom-select-team {
      font-size: 14px;
      width: 100%;
    }
  }
  
  .button-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .button-style {
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 10px;
    width: 231px;
    height: 56px;
    border-radius: 24px;
    font-size: 18px;
    font-weight: 500;
    font-family: "Poppins-Medium", sans-serif !important;
  }
  
  .cancel-button-style {
    border: 1px solid #FF453A;
    color: #FF453A;
    background-color:#FFFFFF;
  }
  
  .save-button-style {
    box-shadow: none !important;
    background-color: #1E5FEA;
    border: 1px solid #FFFFFF;
    color: #FFFFFF;
  }
  
  @media (max-width: 768px) {
    .button-container {
      flex-direction: column;
      align-items: center;
    }
  
    .button-style {
      margin-bottom: 10px;
    }
  }
  