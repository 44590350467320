
.discount-and-offer-hadiing {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.discount-and-offer-container {
    background-color: #fff;
    padding: 20px 40px;
    border-radius: 16px;
    margin-top: 20px;
    height: 625px;
    overflow: scroll;
}

.discount-and-offer-active {
    border-radius: 24px;
    text-transform: none;
    font-size: 14px;
}

.discount-and-offer-details {
    margin-top: 25px;
    background-color: #d3d3d33b;
    padding: 25px;
    border-radius: 16px;
    cursor: pointer;
}

.discount-and-offer-text {
    color: #757575;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px
}

.discount-and-offer-pr {
    color: #2C2C2E;
    text-align: right;
    font-size: 14px;
    font-weight: 600;
}
.discount-and-offer-main{
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}
.discount-and-offer-main-1{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
    cursor: pointer;
}

.delete-discount-Button-container{
    display: flex;
    width: 100%;
    padding: 30px 0 40px
}
.delete-discount-Button{
    width: 100% !important;
    height: 56px !important;
    color: #ffffff !important;
    background-color: #ff453a !important;
    font-family: "Poppins-Medium", sans-serif !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 18px !important;
    text-align: center !important;
    text-transform: none !important;
    border-radius: 28px !important;
    border: 1px solid #ff453a !important;
}

.discount-offer-details {
    background-color: #fff;
    padding: 40px;
    border-radius: 16px;
    margin-top: 20px;
}

.discount-and-offer-subtital {
    display: flex;
    align-items: center;
}

.discount-offer-title {
    color: #48484A;
    font-size: 20px;
    font-weight: 600;
}

.discount-offer-p-tag {
    color: #48484A;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
}

.offer-dicount-flex-container {
    display: flex;
    justify-content: space-between;
    margin-top: 14px;
    flex-wrap: wrap;
}

.offer-flex-left-sub-container {
    display: grid;
}

.offer-flex-right-sub-container {
    display: grid;
}

.offer-add-block-my-time-label {
    color: #48484A;
    font-size: 20px;
    font-weight: 500;
    margin-top: 32px;
}
.discount-offer-title-1{
    color: #48484A;
    font-size: 20px;
    font-weight: 600;
    margin-top: 32px;
}
.discount-offer-p-tag-1{
    color: #48484A;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
}
.nodata{
    text-align: center;
    margin: 40px;
    color: #757575;
}
@media screen and (max-width: 767px) {
    .add-member-service-modal {
      margin-left: 0;
      margin-top: 0;
      height: auto;
      width: auto;
    }
  }
  @media screen and (max-width: 767px) {
    .manage-catalouge-content-container {
      left: 50px;
      top: 750px;
      width: 85%;
      margin-top: 60px;
    }
  }