.booking-main-div {
    overflow: auto;
    background: #F9F9F8;
    height: 100%;

    .booking-confirm-detail-div {
        width: 487px;
        border-radius: 24px;
        background: #ffffff;
        background-blend-mode: normal;
        padding-top: 40px;
        margin: auto;
        margin-bottom: 80px;
        margin-top: 80px;
        padding-bottom: 40px;

        @media (max-width:490px) {
            width: 100%;
        }

        .booking-confirm-list-div {
            width: 407px;
            border-radius: 15px;
            background-blend-mode: normal;
            margin: auto;
            text-align: center;

            @media (max-width:490px) {
                width: 80%;
            }

            h3 {
                margin-top: 20px;
                color: #2c2c2e;
                font-family: Poppins-Medium, sans-serif;
                font-size: 20px;
                font-weight: 500;
                line-height: 24px;
            }

            p {
                color: #2c2c2e;
                font-family: Poppins-Medium, sans-serif;
                font-size: 14px;
                font-weight: 500;
                line-height: 20px;
                text-align: center;
                margin-top: 20px;
            }

            .img-div {
                text-align: center;
                padding-top: 40px;

                img {
                    height: 160px;
                    width: 160px;
                    border-radius: 91px;
                }
            }

            .div-booking-numbr {
                width: 232px;
                height: 86px;
                border-radius: 8px;
                background: #ffffff;
                background-blend-mode: normal;
                margin: auto;
                margin-top: 40px;
                margin-bottom: 30px;

                h4 {
                    color: #3a3a3c;
                    font-family: Poppins-Medium, sans-serif;
                    font-size: 16px;
                    font-weight: 500;
                }
            }

            .book-now-btn-booking-confirmed {
                width: 88%;
                height: 52px;
                background: #1e5fea;
                background-blend-mode: normal;
                border-radius: 24px;
                color: #ffffff;
                margin: 25px;
                margin-top: 10px;
                font-family: Poppins-Medium, sans-serif;
                font-size: 18px;
                font-weight: 500;
                line-height: 16px;
                text-transform: capitalize;
                margin-bottom: 36px;
                span {
                    color: #ffffff;
                    font-size: 18px;
                    font-weight: 500;
                    line-height: 18px;
                    text-align: center;
                }
            }

            .book-now-btn-booking-confirmed:hover {
                width: 88%;
                height: 52px;
                background: #1e5fea;
                background-blend-mode: normal;
                border-radius: 24px;
                color: #ffffff;
                margin: 25px;
                margin-top: 10px;
                font-family: Poppins-Medium, sans-serif;
                font-size: 18px;
                font-weight: 500;
                line-height: 16px;
                text-transform: capitalize;
                margin-bottom: 36px;
                span {
                    color: #ffffff;
                    font-size: 18px;
                    font-weight: 500;
                    line-height: 18px;
                    text-align: center;
                }
            }
        }
    }
}