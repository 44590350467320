.main-div-toast {
  .header-div-toast {
    display: flex;
    flex-direction: row;
    align-items: center;

    p {
      color: #2c2c2e;
      font-family: 'Poppins-medium', 'Gotham', 'Courier New', Courier, monospace;
      font-size: 13px;
      font-weight: 500;
      line-height: 18px;
      letter-spacing: -0.078px;
      display: flex;
      align-items: center;
    }

    img {
      height: 18px;
      width: 18px;
    }
  }
  h2 {
    font-family: 'Poppins-Medium', 'Gotham', 'Courier New', Courier, monospace;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.224px;
  }
  p {
    font-family: 'Poppins', 'Gotham', 'Courier New', Courier, monospace;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
  }
}
.Toastify__toast {
  background: rgba(248, 248, 248, 0.82);
  background-blend-mode: normal;
  backdrop-filter: blur(50px);
}
