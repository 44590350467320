.booking-container {
  margin-left: 40px;
  margin-top: 32px;
}

.arabic-booking-container {
  margin-right: 40px;
  margin-top: 32px;
}

.booking-container-lable {
  font-weight: 600;
  font-size: 24px;
  color: #2c2c2e;
  font-family: "Poppins-Medium", sans-serif !important;
  margin-left: 15px !important;
  margin-top: 15px !important;
}

.booking-container-note {
  font-weight: 500;
  font-size: 12px;
  margin-left: 5px;
  color: #48484a;
  font-family: "Poppins-Medium", sans-serif !important;
}
.booking-container-sublable {
  font-weight: 500;
  font-size: 22px !important;
  color: #2c2c2e;
  font-family: "Poppins-Medium", sans-serif !important;
}
.Manage-booking-container-note {
  font-weight: 400 !important;
  font-size: 14px !important;
  color: #48484a !important;
  font-family: "Poppins-Medium", sans-serif !important;
}
.Manage-booking-container-lable {
  font-weight: 500 !important;
  font-size: 16px !important;
  color: #2c2c2e !important ;
  font-family: "Poppins-Medium", sans-serif !important;
}
.Manage-booking-container-sublable {
  font-weight: 500 !important;
  font-size: 16px !important;
  color: #ff9f0a !important ;
  font-family: "Poppins-Medium", sans-serif !important;
}
.booking-container-dialog {
  font-weight: 500;
  font-size: 20px !important;
  color: #2c2c2e;
  font-family: "Poppins-Medium", sans-serif !important;
}

.booking-container-title {
  font-weight: 500;
  font-size: 32px;
  color: #2c2c2e;
  font-family: "Poppins-Medium", sans-serif !important;
  margin-left: 8px !important;
}

.booking-main-container {
  display: flex;
  margin-top: 32px !important;
}

.booking-papar-container {
  display: flex;
  width: 620px !important;
  height: 820px !important;
  border-radius: 16px !important;
  margin-right: 40px !important;
  margin-bottom: 40px !important;
}

.arabic-booking-papar-container {
  display: flex;
  width: 620px !important;
  height: 820px !important;
  border-radius: 16px !important;
  margin-bottom: 40px !important;
  margin-left: 1% !important;
}

.manage-booking-papar-container {
  display: flex;
  width: 620px !important;
  height: 1220px !important;
  border-radius: 16px !important;
  margin-right: 40px !important;
  margin-bottom: 40px !important;
}
.booking-Paper-left-containers {
  flex-direction: column;
  margin-top: 32px !important;
}
.business-walkin-booking-Paper-left-containers {
  flex-direction: column;
  margin: 32px !important;
  width: 100%;
}
.second {
  margin-left: 44px !important;
}
.custom-divider {
  width: 99%;
  margin: 4px !important;
  /* margin-left: 4px !important; */
}
.booking-id {
  font-weight: 500;
  font-size: 20px;
  color: #2c2c2e;
  font-family: "Poppins-Medium", sans-serif !important;
}

.booking-list-item {
  display: flex;
  border-radius: 5px;
  margin-top: 34px;
  margin-left: 40px;
  margin-right: 30px;
  margin-bottom: 20px;
}

.arabic-booking-list-item {
  display: flex;
  border-radius: 5px;
  margin-top: 34px;
  margin-left: 40px !important;
  margin-right: 30px !important;
  margin-bottom: 20px !important;
}

.booking-list-item .booking-user-info,
.arabic-booking-list-item .booking-user-info {
  display: flex;
  flex-direction: column;
}

.booking-list-item h3,
.arabic-booking-list-item h3 {
  margin: 0;
  color: #2c2c2e;
  font-size: 20px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 150px;
}

.booking-list-item p,
.arabic-booking-list-item p {
  margin: 0;
  color: #757575;
  font-size: 16px;
}

.booking-arrow {
  margin-left: auto !important;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.arabic-booking-arrow {
  margin-right: auto !important;
  cursor: pointer;
  display: flex;
  align-items: center;
}

@media screen and (max-width: 767px) {
  .booking-arrow {
    margin-left: 0 !important;
  }
}

.booking-dialog-button {
  display: flex !important;
  justify-content: center !important;
  width: 154px !important;
  height: 44px !important;
  font-weight: 600 !important;
  font-size: 18px !important;
  border-radius: 24px !important;
  text-transform: none !important;
  background-color: #1e5fea !important;
  /* margin-left: auto; */
}

.booking-input-container {
  display: flex;
  margin-top: 15px;
  flex-direction: column;
}
.booking-dialog-label {
  font-family: "Poppins-Medium", sans-serif;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 10px;
}

.booking-dialog-input-style {
  width: 540px !important;
  height: 54px;
  border-radius: 8px !important;
  font-family: "Poppins-Medium", sans-serif !important;
}

/* Responsive Styles */

/* Small screens */
@media (max-width: 767px) {
  .booking-dialog-input-style {
    width: 100% !important;
    min-width: auto !important;
  }
}

/* Medium screens */
@media (min-width: 768px) and (max-width: 1023px) {
  .booking-dialog-input-style {
    width: 100% !important;
    font-size: 16px;
  }
}

/* Large screens */
@media (min-width: 1024px) {
  .booking-dialog-input-style {
    font-size: 18px;
  }
}

.booking-dialog-input-style-right {
  width: 100% !important;
  height: 54px;
  border-radius: 8px !important;
  font-family: "Poppins-Medium", sans-serif !important;
}
.booking-dialog-input-style-note {
  width: 540px !important;
  height: 54px;
  border-radius: 8px !important;
  font-family: "Poppins-Medium", sans-serif !important;
}

@media (max-width: 768px) {
  .booking-dialog-input-style-right {
    max-width: 100%;
  }
}

.service-label {
  display: flex;
  font-family: "Poppins-Medium", sans-serif !important;
  justify-content: space-between;
  margin-bottom: 10px;
  margin-left: 40px !important;
}

.service-timer {
  display: flex;
  font-family: "Poppins-Medium", sans-serif !important;
  justify-content: space-between;
  margin-bottom: 10px;
  align-items: center;
}
.service-timer .resend {
  color: #1e5fea !important;
  cursor: pointer !important;
}

.service-timer .label {
  margin-left: 0;
}

.service-timer .time {
  /* margin-left: auto; */
  /* margin-right: 44px !important; */
  margin-top: 2px;
}

.booking-client-details-dialog-button {
  width: 231px;
  height: 56px;
  border-radius: 27px !important;
  text-transform: none !important;
  margin-bottom: 30px !important;
  font-size: 18px !important;
  font-family: "Poppins-Medium", sans-serif !important;
}

.continue-button-disable {
  background-color: #628ff0 !important;
  color: #ffffff !important;
  margin-left: 27px !important;
}

.continue-button-enable {
  background-color: #1e5fea !important;
  color: #ffffff !important;
  margin-left: 27px !important;
}

.edit-button {
  background-color: #ff453a !important;
  color: #ffffff !important;
  border: 1px solid #757575 !important;
  /* Add any other styles for the Edit button */
}

.continue-button {
  /* Styles specific to the Continue button */
  background-color: #1e5fea !important;
  color: #ffffff !important;
  margin-left: 26px !important;
  /* Add any other styles for the Continue button */
}
@media (min-width: 480px) {
  .continue-button {
    margin-left: 0 !important;
  }
}
@media (max-width: 768px) {
  .continue-button {
    margin-left: 0 !important;
  }
}
.booking-form-title {
  font-family: "Poppins-Medium", sans-serif;
  font-weight: 500;
  font-size: 24px;
  color: #2c2c2e;
  margin-top: 10px;
  margin-left: 20px !important;
}

.booking-search-textfield {
  margin-top: 20px !important;
  margin-left: 20px !important;
}

.booking-search-service-input-style {
  margin-left: 30px !important;
  margin: auto !important;
  width: 530px !important;
  height: 54px !important;
  color: #757575 !important;
  border-radius: 24px !important;
  font-family: "Poppins-Medium", sans-serif !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  max-width: 100% !important;
}

.booking-dialog-title {
  display: flex;
  flex-direction: column;
}
.booking-dialog-Client-Details {
  width: 683px !important;
  height: 1064px !important;
  background: #ffffff;
  border-radius: 16px;
}
.booking-employee-assign-dialog {
  width: 493px !important;
  height: 470px !important;
  background: #ffffff;
  border-radius: 16px;
}
.booking-service-button {
  margin-left: 8px !important;
  border-radius: 16px !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  text-transform: none !important ;
  font-family: "Poppins-Medium", sans-serif !important;
}

.availablestaff-dialog-Client-Details {
  width: 576px;
  height: 589px;
  background: #ffffff;
  border-radius: 16px;
}
.loder-dialog-Client-Details {
  width: 145px !important;
  height: 141px !important;
  background: #ffffff;
  border-radius: 16px;
  box-shadow: none !important;
}

.availablestaff-main-container {
  margin-left: 20px !important;
  display: flex;
  flex-direction: column !important;
  margin-right: 10px;
  margin-top: 10px !important;
}

.loader-container {
  display: flex !important;
  justify-content: center !important;
  text-align: center !important;
  margin-top: 20px !important;
}

.assign-container {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  text-align: center !important;
}
.assign-container-lable {
  font-family: "Poppins-Medium", sans-serif !important;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 24px !important;
}
.assign-container-sublable {
  font-family: "Poppins-Medium", sans-serif !important;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 16px !important;
  color: #2c2c2e !important;
}

.walkin-manage-services-item-container {
  display: flex;
  justify-content: space-between;
  align-content: center;
  padding: 0 20px;
  margin-bottom: 20px;
  border-radius: 24px !important;
}

.walkin-booking-cancel-start-button-container {
  margin-top: 40px;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-evenly;
}

.walkin-call_us-btn {
  display: inline-flex;
  border-radius: 23px;
  background: #1e5fea;
  background-blend-mode: normal;
  color: #ffffff;
  font-family: Poppins-Medium, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  height: 46px;
  width: 130px;
  margin: 10;
  text-align: center;
  align-items: center;
  padding: 15px;
  margin-top: 0;
  text-decoration: auto;
  text-transform: capitalize;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

@media (max-width: 590px) {
  .walkin-call_us-btn {
    height: 35px;
    width: 90px;
    font-size: 10px;
  }

  .walkin-call_us-btn img {
    height: 10px;
    width: 10px;
  }

  .booking-list-item {
    margin-left: 5px !important;
  }

  .arabic-booking-list-item {
    margin-left: 5px !important;
    margin-right: auto !important;
  }

  .booking-list-item h3,
  .arabic-booking-list-item h3 {
    font-size: 12px;
  }

  .booking-list-item p,
  .arabic-booking-list-item p {
    font-size: 10px;
  }
}

.stop-button {
  display: flex;
  height: 56px;
  width: 419px;
  border-radius: 27px !important;
  margin-bottom: 30px !important;
  text-transform: none !important;
  background-color: #ff453a !important;
  font-size: 18px !important;
  margin-left: 50px !important;
  font-family: "Poppins-Medium", sans-serif !important;
  color: #ffffff !important;
}
.start-button {
  width: 498px;
  height: 56px;
  display: flex;
  border-radius: 27px !important;
  margin-bottom: 30px !important;
  text-transform: none !important;
  font-size: 18px !important;
  color: #ffffff !important;
  background-color: #1e5fea !important;
  font-family: "Poppins-Medium", sans-serif !important;
}

.review-button {
  width: 419px;
  height: 56px;
  display: flex;
  border-radius: 27px !important;
  margin-bottom: 30px !important;
  margin-left: 50px !important;
  text-transform: none !important;
  font-size: 18px !important;
  border: 1px solid #757575 !important;
  color: #2c2c2e !important;
  background-color: #ffffff !important;
  font-family: "Poppins-Medium", sans-serif !important;
}
.booking-status {
  color: #34c759 !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  font-family: "Poppins-Medium", sans-serif !important;
}
.addclient-error-message-text {
  font-family: "Poppins-Medium", sans-serif !important;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 12px !important;
  color: #ff453a !important;
  margin-top: 12px;
  margin-top: 8px !important;
}

.walkin-manage-employee-service-main-content-container {
  margin: 32px 44px 0px 44px;
  height: 100vh;
  overflow-y: scroll;
}

@media (min-width: 1024px) and (max-width: 1290px) {
  .walkin-manage-employee-service-main-content-container {
    margin: 28px 40px 0px 40px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .walkin-manage-employee-service-main-content-container {
    margin: 28px 30px 0px 30px;
  }
}
@media (max-width: 767px) {
  .booking-container {
    margin-left: 20px;
  }
  .arabic-booking-container {
    margin-right: 20px;
  }
  .booking-papar-container {
    width: 100% !important;
    height: 820px !important;
  }
  .arabic-booking-papar-container {
    width: 100% !important;
    height: 820px !important;
  }
}
