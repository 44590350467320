.main-available-div {
  width: 683px;
  border-radius: 24px;
  background: #ffffff;
  background-blend-mode: normal;
  margin: auto;
  margin-top: 50px;
  padding: 50px;
  margin-bottom: 100px;

  @media (max-width: 685px) {
    width: 100%;
  }

  h1 {
    height: 16px;
    color: #2c2c2e;
    font-family: "Poppins-medium", "Gotham", "Courier New", Courier, monospace;
    font-size: 24px;
    font-weight: 500;
    line-height: 16px;
  }

  .h1-tag-title {
    height: 35px;
    color: #2c2c2e;
    font-family: "Poppins-medium", "Gotham", "Courier New", Courier, monospace;
    font-size: 24px;
    font-weight: 500;
    line-height: 35px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .btn-pair-of-skip-cont {
    margin-top: 20px;

    .skip-btn {
      width: 48% !important;
      height: 58px;
      border-radius: 32px !important;
      border: 1px solid !important;
      text-transform: capitalize;
      font-size: 18px;
      font-weight: 500;
      line-height: 30px;
      text-align: center;
      margin: inherit;
      margin-bottom: 0px;

      @media (max-width: 528px) {
        width: 100% !important;
      }
      span {
        font-family: "Poppins-Medium";
      }
    }

    .cont-btn {
      width: 48% !important;
      height: 58px;
      border-radius: 32px !important;
      background: #1e5fea;
      color: #ffff !important;
      background-blend-mode: normal;
      text-transform: capitalize;
      margin: inherit;
      font-size: 18px;
      font-weight: 500;
      line-height: 30px;
      text-align: center;

      @media (max-width: 528px) {
        width: 100% !important;
      }
      span {
        font-family: "Poppins-Medium";
      }
    }
  }

  .main-scroll-list {
    overflow: auto;
    height: 417px;

    .MuiFormControl-root {
      width: 100% !important;
    }

    .data-div {
      margin: auto;
      display: flex;
      margin-top: 26px;
      align-items: center;
      max-width: 100%;
      width: 100%;
      cursor: pointer;

      @media (max-width: 685px) {
        margin: 16px;
        width: 75%;
      }

      label {
        margin-left: auto !important;
      }

      .detail-div {
        padding-left: 18px;
        width: 80%;

        h1 {
          height: 18px;
          color: #2c2c2e;
          font-family: "Poppins-medium", "Gotham", "Courier New", Courier,
            monospace;
          font-size: 20px;
          font-weight: 500;
          line-height: 19px;
          padding-bottom: 27px;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          @media (max-width: 685px) {
            height: auto;
            padding-bottom: 6px;
            line-height: 22px;
          }
        }

        p {
          height: 16px;
          color: #757575;
          font-family: "Poppins-medium", "Gotham", "Courier New", Courier,
            monospace;
          font-size: 16px;
          font-weight: 400;
          line-height: 16px;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      .arabic-detail-div {
        padding-left: 0px !important;
        padding-right: 18px !important;
      }

      .img-tag {
        width: 56;
        height: 56;
        border-radius: 42px;
      }

      .radio-group-component-item {
        margin-left: auto;
      }
    }

    .arabic-data-div {
      label {
        margin-left: 0 !important;
        margin-right: auto !important;
      }
    }
  }

  .input-div {
    .input-icon {
      width: 15px;
      margin-left: 20px;
    }

    input::placeholder {
      font-family: "Poppins", "Gotham", "Courier New", Courier, monospace;
      font-weight: 600;
      color: #000000;
    }

    .seache-sevice-input-avaialabe {
      width: 600px !important;

      @media (max-width: 685px) {
        width: 100% !important;
      }

      .MuiInputBase-root {
        height: 56px;
        border-radius: 29px;
        border: 1px solid #48484a;
      }
    }
  }
}
