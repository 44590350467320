.mybooking-main-div {
    .btn-pair-of-filters {
        display: flex;
        background: #ffffff;
        width: 530px;
        height: 80px;
        border-radius: 34px;
        margin: auto;
        margin-top: 60px;

        @media (max-width:530px) {
            width: 100% !important;
        }

        .up-btn {
            width: 49% !important;
            height: 58px;
            border-radius: 32px !important;
            background: #1e5fea;
            color: #ffff;
            background-blend-mode: normal;
            text-transform: capitalize;
            margin: 10px 0px 12px 10px;
            font-size: 18px;
            font-weight: 500;
            line-height: 30px;
            text-align: center;

            span {
                font-family: "Poppins-medium", "Gotham", "Courier New", Courier, monospace;
            }
        }

        .past-btn {
            width: 49% !important;
            height: 58px;
            border-radius: 32px !important;
            background: #1e5fea;
            color: #ffff;
            background-blend-mode: normal;
            text-transform: capitalize;
            margin: 10px 10px 10px 0px;
            font-size: 18px;
            font-weight: 500;
            line-height: 30px;
            text-align: center;

            span {
                font-family: "Poppins-medium", "Gotham", "Courier New", Courier, monospace;
            }
        }
    }

    .list-of-all-booking-div {
        width: 643px;
        margin: auto;
        overflow: auto;
        height: 600px;
        margin-bottom: 35px;

        @media (max-width:644px) {
            width: 100%;
        }

        .listitem-div {
            border-radius: 15px;
            background: #fff;
            padding: 30px;
            margin-top: 30px;

            .sub-item {
                display: flex;
                flex-wrap: wrap;

                p {
                    color: #757575;
                    font-family: Poppins;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 21px;
                    font-family: "Poppins-medium", "Gotham", "Courier New", Courier, monospace;
                }
            }

            .item-detail-div {
                .profile-detail {
                    display: flex;
                    flex-wrap: wrap;
                    padding-top: 15px;
                    padding-bottom: 15px;

                    img {
                        width: 45px;
                        height: 45px;
                        border-radius: 100px;
                        margin-top: 6px;
                    }

                    .profile-detail-sub {
                        padding-left: 10px;
                        width: 90%;

                        h4 {
                            color: #757575;
                            font-family: "Poppins-medium", "Gotham", "Courier New", Courier, monospace;
                            font-size: 14px;
                            font-weight: 400;
                            line-height: 21px;
                            padding-top: 6px;
                            direction: initial;
                        }


                        .title-names {
                            display: flex;
                            flex-wrap: wrap;

                            h3 {
                                color: #2c2c2e;
                                font-family: "Poppins-medium", "Gotham", "Courier New", Courier, monospace;
                                font-size: 20px;
                                font-weight: 500;
                                line-height: 30px;
                            }

                        }
                    }

                    h2 {
                        color: #2c2c2e;
                        font-family: "Poppins-medium", "Gotham", "Courier New", Courier, monospace;
                        font-size: 14px;
                        font-weight: 500;
                        text-align: right;
                        margin-left: auto;

                        @media (max-width:393px) {
                            margin-left: initial;
                            padding-left: 55px;
                        }
                    }
                }

                hr {
                    margin-left: -30px;
                    width: 642px;
                    border-color: rgba(0, 0, 0, 0.12);
                }

                .service-div {
                    display: flex;
                    flex-wrap: wrap;
                    padding-top: 15px;
                    padding-bottom: 15px;

                    p {
                        color: #48484a;
                        font-family: "Poppins-medium", "Gotham", "Courier New", Courier, monospace;
                        font-size: 16px;
                        font-weight: 500;
                    }

                    h6 {
                        color: #ff9f0a;
                        font-family: "Poppins-medium", "Gotham", "Courier New", Courier, monospace;
                        font-size: 16px;
                        font-weight: 500;
                        text-align: right;
                    }
                }

                .Need-div {
                    display: flex;
                    flex-wrap: wrap;
                    padding-top: 15px;

                    p {
                        color: #48484a;
                        font-family: "Poppins-medium", "Gotham", "Courier New", Courier, monospace;
                        font-size: 16px;
                        font-weight: 500;
                        text-decoration: underline;
                        cursor: pointer;
                    }

                    h6 {
                        color: #ff9f0a;
                        font-family: "Poppins-medium", "Gotham", "Courier New", Courier, monospace;
                        font-size: 16px;
                        font-weight: 500;
                        text-align: right;
                    }
                }
            }
        }
    }
}

.mybookingdetail-main-div {
    width: 600px;
    margin: auto;
    margin-top: 50px;
    margin-bottom: 30px;

    @media (max-width:600px) {
        width: 100%;
    }

    .r-btn {
        height: 58px;
        border-radius: 32px !important;
        background: #fff;
        border: 1px solid #2c2c2e;
        color: #2c2c2e;
        background-blend-mode: normal;
        text-transform: inherit;
        margin: 50px 0px 12px 0px;
        font-size: 18px;
        font-weight: 500;
        line-height: 30px;
        text-align: center;

        span {
            font-family: "Poppins-medium", "Gotham", "Courier New", Courier, monospace;
        }
    }

    .a-btn,
    .p-btn,
    .s-btn,
    .o-btn {
        height: 58px;
        border-radius: 32px !important;
        color: #2c2c2e;
        background: #f9f9f8;
        background-blend-mode: normal;
        text-transform: inherit;
        margin: 10px 0px 12px 0px;
        font-size: 18px;
        font-weight: 500;
        line-height: 30px;
        text-align: center;

        span {
            font-family: "Poppins-medium", "Gotham", "Courier New", Courier, monospace;
        }
    }

    .p-query {
        color: #2c2c2e;
        font-size: 16px;
        font-weight: 500;
        text-align: center;
        margin: 10px;
        font-family: "Poppins-medium", "Gotham", "Courier New", Courier, monospace;
        cursor: pointer;
    }

    .btn-pair-of-filters {
        display: flex;
        background: #ffffff;
        height: 80px;
        border-radius: 34px;
        margin: auto;
        margin-top: 25px;

        @media (max-width:530px) {
            width: 100%;
        }

        .up-btn {
            width: 49% !important;
            height: 58px;
            border-radius: 32px !important;
            background: #fff;
            border: 1px solid #ff453a;
            color: #ff453a;
            background-blend-mode: normal;
            text-transform: capitalize;
            margin: 10px 0px 12px 10px;
            font-size: 18px;
            font-weight: 500;
            line-height: 30px;
            text-align: center;

            span {
                font-family: "Poppins-medium", "Gotham", "Courier New", Courier, monospace;
            }
        }

        .past-btn {
            width: 49% !important;
            height: 58px;
            border-radius: 32px !important;
            background: #1e5fea;
            color: #ffff;
            background-blend-mode: normal;
            text-transform: capitalize;
            margin: 10px 10px 10px 0px;
            font-size: 18px;
            font-weight: 500;
            line-height: 30px;
            text-align: center;

            span {
                font-family: "Poppins-medium", "Gotham", "Courier New", Courier, monospace;
            }
        }
    }

    .suber-item {
        display: flex;
        flex-wrap: wrap;
        margin-top: 15px;
        margin-bottom: 15px;

        p {
            color: #757575;
            font-family: Poppins;
            font-size: 16px;
            font-weight: 500;
            line-height: 21px;
            font-family: "Poppins-medium", "Gotham", "Courier New", Courier, monospace;
        }
    }

    .suberServee-item {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 20px;

        h1 {
            font-family: "Poppins-medium", "Gotham", "Courier New", Courier, monospace;
            font-size: 16px;
            font-weight: 600;
            text-align: right;
        }
    }

    .suberServ-item {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 30px;

        p {
            color: #2c2c2e;
            margin-top: -6px;
            font-size: 24px;
            font-weight: 600;
            line-height: 24px;
            font-family: "Poppins-medium", "Gotham", "Courier New", Courier, monospace;
        }

        h1 {
            color: #ff9f0a;
            font-family: "Poppins-medium", "Gotham", "Courier New", Courier, monospace;
            font-size: 16px;
            font-weight: 600;
            text-align: right;
        }
    }

}

.modal-booknow-invoice {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 311px;
    height: auto;
    border-radius: 24px;
    background: #ffffff;
    background-blend-mode: normal;

    @media (max-width:312px) {
        width: 100%;
    }

    .img-debit-card-div-invoice {
        .img-debit-card-invoice {
            .p-tag-invoice {
                color: #48484a;
                font-family: "Poppins-medium", "Gotham", "Courier New", Courier, monospace;
                font-size: 16px;
                font-weight: 500;
                line-height: 24px;
                text-align: center;
                padding-right: 60px;
                padding-left: 60px;
            }
        }
    }

    .book-now-btn-modal-debit-invoice {
        height: 58px;
        border-radius: 32px !important;
        background: #1e5fea;
        color: #ffff;
        background-blend-mode: normal;
        text-transform: capitalize;
        font-size: 18px;
        font-weight: 500;
        line-height: 30px;
        text-align: center;
        width: 90%;
        margin: 15px;

        span {
            font-family: "Poppins-medium", "Gotham", "Courier New", Courier, monospace;
        }
    }

    .book-now-btn-modal-debit-invoice:hover {
        height: 58px;
        border-radius: 32px !important;
        background: #1e5fea;
        color: #ffff;
        background-blend-mode: normal;
        text-transform: capitalize;
        font-size: 18px;
        font-weight: 500;
        line-height: 30px;
        text-align: center;
        width: 90%;
        margin: 15px;

        span {
            font-family: "Poppins-medium", "Gotham", "Courier New", Courier, monospace;
        }
    }
}

.modal-booknow-cancel {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 413px;
    height: auto;
    border-radius: 24px;
    background: #ffffff;
    background-blend-mode: normal;
    padding: 20px;

    @media (max-width:413px) {
        width: 100%;
    }

    .img-debit-card-div-cancel {
        .img-debit-card-cancel {
            .p-tag-cancel {
                color: #48484a;
                font-family: "Poppins-medium", "Gotham", "Courier New", Courier, monospace;
                font-size: 16px;
                font-weight: 500;
                line-height: 24px;
                text-align: center;
                padding-right: 70px;
                padding-left: 70px;
                margin-bottom: 20px;
            }

            .h1-tag-cancel {
                color: #2c2c2e;
                font-family: "Poppins-medium", "Gotham", "Courier New", Courier, monospace;
                font-size: 20px;
                font-weight: 500;
                text-align: center;
                margin-top: 20px;
                margin-bottom: 5px;
            }
        }
    }

    .book-now-btn-modal-debit-cancel {
        height: 58px;
        border-radius: 32px !important;
        background: #1e5fea;
        color: #ffff;
        background-blend-mode: normal;
        text-transform: capitalize;
        font-size: 18px;
        font-weight: 500;
        line-height: 30px;
        text-align: center;
        width: 90%;
        margin: 15px;

        span {
            font-family: "Poppins-medium", "Gotham", "Courier New", Courier, monospace;
        }
    }

    .book-now-btn-modal-debit-cancel:hover {
        height: 58px;
        border-radius: 32px !important;
        background: #1e5fea;
        color: #ffff;
        background-blend-mode: normal;
        text-transform: capitalize;
        font-size: 18px;
        font-weight: 500;
        line-height: 30px;
        text-align: center;
        width: 90%;
        margin: 15px;

        span {
            font-family: "Poppins-medium", "Gotham", "Courier New", Courier, monospace;
        }
    }

    .book-now-btn-modal-debit-cancel-yes {
        height: 58px;
        border-radius: 32px !important;
        background: #fff;
        color: #2c2c2e;
        background-blend-mode: normal;
        text-transform: capitalize;
        font-size: 18px;
        font-weight: 500;
        line-height: 30px;
        text-align: center;
        width: 90%;
        margin: 15px;
        border: 1px solid #979797;
        margin-bottom: 40px;

        span {
            font-family: "Poppins-medium", "Gotham", "Courier New", Courier, monospace;
        }
    }

    .book-now-btn-modal-debit-cancel-yes:hover {
        height: 58px;
        border-radius: 32px !important;
        background: #fff;
        color: #2c2c2e;
        background-blend-mode: normal;
        text-transform: capitalize;
        font-size: 18px;
        font-weight: 500;
        line-height: 30px;
        text-align: center;
        width: 90%;
        margin: 15px;
        border: 1px solid #979797;
        margin-bottom: 40px;

        span {
            font-family: "Poppins-medium", "Gotham", "Courier New", Courier, monospace;
        }
    }
}

.needidiv {
    width: 467px;
    margin: auto;
    margin-top: 50px;

    .need-btn {
        height: 58px;
        border-radius: 32px !important;
        color: #2c2c2e;
        background: #ffffff !important;
        background-blend-mode: normal;
        text-transform: inherit;
        margin: 10px 0px 12px 0px;
        font-size: 18px;
        font-weight: 500;
        line-height: 30px;
        text-align: center;

        span {
            font-family: "Poppins-medium", "Gotham", "Courier New", Courier, monospace;
        }
    }

    .MuiInputBase-inputMultiline::placeholder {
        color: #000000;
        font-family: Poppins-Medium, sans-serif;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
    }

    .textArea-issue {
        width: 432px !important;
        background: #ffff;

    }

    .btn-pair-of-filters {
        display: flex;
        height: 80px;
        border-radius: 34px;
        margin: auto;
        margin-top: 60px;

        @media (max-width:530px) {
            width: 100%;
        }


        .past-btn {
            width: 94% !important;
            height: 58px;
            border-radius: 32px !important;
            background: #1e5fea;
            color: #ffff;
            background-blend-mode: normal;
            text-transform: capitalize;
            font-size: 18px;
            font-weight: 500;
            line-height: 30px;
            text-align: center;

            span {
                font-family: "Poppins-medium", "Gotham", "Courier New", Courier, monospace;
            }
        }
    }
}

.modal-booknow-cancel-review {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 413px;
    height: auto;
    border-radius: 24px;
    background: #ffffff;
    background-blend-mode: normal;
    padding: 30px;

    @media (max-width:413px) {
        width: 100%;
    }

    .img-debit-card-div-cancel-review {
        .img-debit-card-cancel-review {
            .h1-tag-cancel-review {
                color: #2c2c2e;
                font-family: "Poppins-medium", "Gotham", "Courier New", Courier, monospace;
                font-size: 20px;
                font-weight: 500;
                text-align: center;
                margin-bottom: 5px;
            }

            .textArea-issue-review {
                width: 100% !important;
                margin-top: 12px;
                margin-bottom: 10px;
                background: #f9f9f8;
            }
        }
    }

    .review-rating {
        text-align: center;
        margin-top: 20px;

        .rating-h1 {
            color: #2c2c2e;
            font-family: Poppins-Medium, sans-serif;
            font-size: 24px;
            font-weight: 500;
            line-height: 24px;
        }

        .rating-star {
            margin-top: 20px;

            label {
                margin-right: 5px;

                span {
                    img {
                        width: 40px !important;
                    }
                }
            }
        }
    }

    .btn-pair-of-filters {
        display: flex;
        height: 80px;
        border-radius: 34px;
        margin: auto;
        margin-top: 30px;

        @media (max-width:530px) {
            width: 100%;
        }

        .up-btn {
            width: 46% !important;
            height: 58px;
            border-radius: 32px !important;
            border: 1px solid #2c2c2e;
            color: #3a3a3c;
            background-blend-mode: normal;
            text-transform: capitalize;
            font-size: 18px;
            font-weight: 500;
            line-height: 30px;
            text-align: center;

            span {
                font-family: "Poppins-medium", "Gotham", "Courier New", Courier, monospace;
            }
        }

        .past-btn {
            width: 46% !important;
            height: 58px;
            border-radius: 32px !important;
            background: #1e5fea;
            color: #ffff;
            background-blend-mode: normal;
            text-transform: capitalize;
            font-size: 18px;
            font-weight: 500;
            line-height: 30px;
            text-align: center;

            span {
                font-family: "Poppins-medium", "Gotham", "Courier New", Courier, monospace;
            }
        }
    }
}

.modal-booknow-confirmed-review {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    height: auto;
    border-radius: 24px;
    background: #ffffff;
    background-blend-mode: normal;
    max-width: 100%;
    max-height: 100%;
    overflow: auto;

    @media (max-width:312px) {
        width: 100%;
    }

    .img-debit-card-div-confirmed-review {
        .img-debit-card-confirmed-review {
            p {
                color: #48484a;
                font-family: "Poppins-medium", "Gotham", "Courier New", Courier, monospace;
                font-size: 16px;
                font-weight: 500;
                line-height: 24px;
                text-align: center;
                padding-right: 20px;
                padding-left: 20px;
                margin-top: 20px;
                margin-bottom: 30px;
            }

            h1 {
                text-align: center;
                color: #2c2c2e;
                font-family: "Poppins-medium", "Gotham", "Courier New", Courier, monospace;
                font-size: 22px;
                font-weight: 500;
                line-height: 24px;
                margin-top: 30px;
            }
        }
    }

    .book-now-btn-modal-debit-confirmed-review {
        height: 58px;
        border-radius: 32px !important;
        background: #1e5fea;
        color: #ffff;
        background-blend-mode: normal;
        text-transform: capitalize;
        font-size: 18px;
        font-weight: 500;
        line-height: 30px;
        text-align: center;
        width: 90%;
        margin-bottom: 40px !important;
        margin: 20px;

        span {
            font-family: "Poppins-medium", "Gotham", "Courier New", Courier, monospace;
        }
    }

    .book-now-btn-modal-debit-confirmed-review:hover {
        height: 58px;
        border-radius: 32px !important;
        background: #1e5fea;
        color: #ffff;
        background-blend-mode: normal;
        text-transform: capitalize;
        font-size: 18px;
        font-weight: 500;
        line-height: 30px;
        text-align: center;
        width: 90%;
        margin-bottom: 40px !important;
        margin: 20px;

        span {
            font-family: "Poppins-medium", "Gotham", "Courier New", Courier, monospace;
        }
    }
}

.main-profile-booking {
    @media (max-width:1207px) {
        margin-top: 64px !important;
        margin-left: 50px !important;
    }

    @media (max-width:500px) {
        width: 100%;
        margin-top: 64px !important;
        margin-left: 0px !important;
    }

    .div-profile-book {
        @media (max-width:500px) {
            margin-bottom: 20px !important;
            margin-left: 20px !important;
        }
    }
}

.filter-buttons-main-conatiner {
    width: 100% !important;
    max-width: 100% !important;

    .MuiFormGroup-root {
        .MuiFormControlLabel-labelPlacementStart {
            margin-left: 0;
        }
    }

}