.main-div {
    overflow: auto;
    background: #F9F9F8;
    height: 100%;

    .input-div {
        text-align: center;
        margin-top: 40px;

        .seache-sevice-input {
            width: 672px !important;

            @media (max-width:700px) {
                width: 100% !important;
            }

            .MuiInputBase-root {
                height: 56px;
                border-radius: 29px;
                border: 1px solid #48484a;
            }

            .input-icon {
                margin-left: 25px;
            }
        }
    }

    .services-header {
        background: #3d40c3;
        display: flex;
        height: 150px;
        padding: 50px;
        align-items: center;
        margin: 0 auto;
        max-width: 1440px;

        @media screen and (max-width : 767px) {
            padding:20px ;
            height: 70px;
        }

        img {
            padding-left: 25px;
            cursor: pointer;
        }

        h1 {
            padding-left: 30px;
            color: #ffffff;
            font-family: Poppins, "Gotham", "Courier New", Courier, monospace;
            font-size: 32px;
            font-weight: 500;
            line-height: 45px;
            margin-right: 15px;

            @media screen and (max-width : 767px) {
                font-size: 20px;
                line-height: normal;
            }
        }

        div {
            min-height: 25px;
            height: auto;
            border-radius: 13px;
            background: #ffffff;
            background-blend-mode: normal;
            margin-top: 6px;

            p {
                color: #48484a;
                font-family: Poppins, "Gotham", "Courier New", Courier, monospace;
                font-size: 14px;
                font-weight: 500;
                text-align: center;
                padding: 2px 10px;
            }
        }
    }

    .main-service-div {
        margin: auto;
        margin-top: 40px;
        width: 672px;

        @media (max-width:700px) {
            width: 100%;
        }

        .pill-main {
            min-height: 66px;
            height: auto;
            border-radius: 37px;
            background: #1e5fea;
            background-blend-mode: normal;
            margin-top: 40px;
            margin-bottom: 24px;

            p {
                color: #ffffff;
                font-family: Poppins, "Gotham", "Courier New", Courier, monospace;
                font-size: 32px;
                font-weight: 500;
                padding: 10px;
                padding-left: 35px;
            }
        }

        .service-item {
            cursor: pointer;
            padding: 24px;

            .first-service {
                display: flex;
                flex-wrap: wrap;
                align-items: baseline;

                p {
                    color: #2c2c2e;
                    font-family: Poppins, "Gotham", "Courier New", Courier, monospace;
                    font-size: 24px;
                    font-weight: 500;
                    position: initial;
                }

                h6 {
                    color: #ff453a;
                    font-family: Poppins, "Gotham", "Courier New", Courier, monospace;
                    font-size: 16px;
                    font-weight: 500;
                }
            }
            
            .sec-service {
                display: flex;
                flex-wrap: wrap;
                align-items: baseline;
                margin-top: -7px;
                p {
                    margin-left: auto;
                    font-size: 15px;
                    text-decoration: line-through;
                    text-decoration-color: red;
                    position: initial;
                }

                h3 {
                    color: #2c2c2e;
                    font-family: Poppins, "Gotham", "Courier New", Courier, monospace;
                    font-size: 20px;
                    font-weight: 500;
                    text-align: right;
                }

               
            }
            .discount-price{
                h6{
                    text-align: end;
                    color: #48484a;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 21px;
                    font-size: 15px;
                    text-decoration: line-through;
                    -webkit-text-decoration-color: red;
                    text-decoration-color: red;
                    position: initial;
                    padding-right: 18px;
                }
            }
            p {
                color: #636366;
                font-family: Poppins, "Gotham", "Courier New", Courier, monospace;
                font-size: 14px;
                font-weight: 500;
                margin-top: -30px;
            }
        }
    }

    .calendar-div {
        text-align: -webkit-center;
        text-align: -moz-center;

        .react-calendar {
            width: 650px;
            border-radius: 24px;
            background: #ffffff;
            background-blend-mode: normal;
            border: none;

            .react-calendar__navigation:hover {
                background: #ffffff;
            }

            .react-calendar__navigation {
                width: 260px !important;
                margin-top: 50px;
                padding-top: 50px;
                padding-bottom: 34px;
                align-items: center;

                .react-calendar__navigation__label {
                    height: 46px;
                    color: #2c2c2e;
                    font-family: Poppins-Medium, sans-serif;
                    font-size: 24px;
                    font-weight: 600;
                    line-height: 40px;
                    flex-grow: 0 !important;

                    span {
                        padding-left: 20px;
                        padding-right: 20px;
                    }
                }

                button:disabled {
                    background-color: #ffffff !important;
                }

                .react-calendar__navigation__prev2-button {
                    display: none;
                }

                .react-calendar__navigation__next2-button {
                    display: none;
                }
            }

            .react-calendar__viewContainer {
                .react-calendar__month-view {
                    padding-left: 20px;
                    padding-right: 20px;

                    .react-calendar__month-view__weekdays {
                        abbr {
                            text-decoration: none !important;
                            color: #48484a;
                            font-size: 20px;
                            font-weight: 400;
                            line-height: 14px;
                            text-align: center;
                            font-family: 'Poppins-Medium';
                            text-transform: uppercase;

                            @media (max-width:500px) {
                                font-size: 18px;
                            }
                        }
                    }

                    .react-calendar__month-view__days {
                        .current-date-highlight {
                            abbr {
                                text-decoration: underline;
                                text-underline-position: under;
                                height: 85px;
                                font-size: 18px;
                                // padding: 25px;
                                padding-top: 25px;
                                border-radius: 61px;
                                padding-bottom: 20px;
                            }
                        }

                        .disable-date-highlight {
                            pointer-events: none;

                            abbr {
                                background: #d8d8d8 !important;
                                height: 85px;
                                font-size: 18px;
                                padding: 25px;
                                padding-top: 25px;
                                border-radius: 61px;
                                padding-bottom: 20px;
                                color: #48484a !important;
                                font-weight: 600;
                                font-family: 'Poppins-Medium';

                                @media (max-width:590px) {
                                    padding: 9px;
                                    padding-top: 9px;
                                    padding-bottom: 9px;
                                }
                            }
                        }

                        .react-calendar__tile:disabled {
                            background-color: #ffffff !important;

                            abbr:hover {
                                background: #ffffff !important;
                                height: 85px;
                                font-size: 18px;
                                padding: 25px;
                                padding-top: 25px;
                                border-radius: 61px;
                                padding-bottom: 20px;
                                color: #75757599 !important;
                                font-weight: 500;
                                font-family: 'Poppins-Medium';

                                @media (max-width:590px) {
                                    padding: 9px;
                                    padding-top: 9px;
                                    padding-bottom: 9px;
                                }
                            }

                            abbr {
                                background: #ffffff !important;
                                height: 85px;
                                font-size: 18px;
                                padding: 25px;
                                padding-top: 25px;
                                border-radius: 61px;
                                padding-bottom: 20px;
                                color: #75757599 !important;
                                font-weight: 500;
                                font-family: 'Poppins-Medium';

                                @media (max-width:590px) {
                                    padding: 9px;
                                    padding-top: 9px;
                                    padding-bottom: 9px;
                                }
                            }
                        }


                        abbr {
                            height: 58px;
                            color: #48484a;
                            font-size: 18px;
                            font-weight: 400;
                            line-height: 40px;
                            text-align: center;
                        }

                        .react-calendar__tile {
                            height: 85px;
                            border-radius: 61px;
                            max-width: 86px;
                            background: #ffffff;
                        }

                        .react-calendar__tile:hover {
                            height: 85px;
                            border-radius: 61px;
                            max-width: 86px;
                            background: #ffffff;
                        }

                        .react-calendar__tile {
                            abbr:hover {
                                color: #ffffff;
                                background: #1b5add;
                                height: 85px;
                                font-size: 18px;
                                padding: 25px;
                                padding-top: 23px;
                                border-radius: 61px;
                                padding-bottom: 20px;

                                @media (max-width:590px) {
                                    padding: 9px;
                                    padding-top: 9px;
                                    padding-bottom: 9px;
                                }
                            }
                        }

                        .react-calendar__tile--active {
                            background: #ffffff;
                            height: 85px;
                            border-radius: 61px;
                            max-width: 86px;

                            // margin-left: 12px;
                            abbr {
                                color: #ffffff;
                                background: #1b5add;
                                height: 85px;
                                font-size: 18px;
                                padding: 25px;
                                padding-top: 23px;
                                border-radius: 61px;
                                padding-bottom: 20px;

                                @media (max-width:590px) {
                                    padding: 9px;
                                    padding-top: 9px;
                                    padding-bottom: 9px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .p-require-tag {
        text-align: initial;
        width: 629px;
        margin-top: 10px;
        color: red;
        font-size: 14px;

        @media (max-width:640px) {
            width: 100%;
        }
    }

    .available-div {
        display: flex;
        flex-wrap: wrap;
        width: 650px;
        margin: auto;
        margin-bottom: 15px;
        margin-top: 40px;
        align-items: baseline;
        padding: 10px;

        @media (max-width:652px) {
            width: 100%;
        }

        h1 {
            color: #2c2c2e;
            font-family: Poppins-Medium, sans-serif;
            font-size: 32px;
            font-weight: 500;
            line-height: 48px;
        }

        p {
            margin-left: auto;
            color: #ff453a;
            font-family: Poppins-Medium, sans-serif;
            font-size: 24px;
            font-weight: 500;
            line-height: 24px;
            text-align: right;
        }

        .arabic-p-tag{
            margin-right: auto !important;
            margin-left: 0 !important;
        }
    }

    .timeslot-div {
        width: 650px;
        border-radius: 24px;
        background: #ffffff;
        background-blend-mode: normal;
        margin: auto;
        padding: 50px;
        margin-bottom: 50px;
        @media (max-width:652px) {
            width: 100%;
        }

        .no_staff {
            display: flex;
            height: 45px;
            border-radius: 8px;
            background: #f9f9f8;
            background-blend-mode: normal;
            align-items: center;
            padding-left: 10px;
            margin-top: 20px;
            p {
                font-family: Poppins-Medium, sans-serif;
                font-size: 14px;
                font-weight: 400;
                line-height: 18px;
                padding-left: 10px;
            }
        }

        .book-now-btn-continue {
            width: 55%;
            height: 56px;
            background: #1e5fea;
            background-blend-mode: normal;
            border-radius: 24px;
            color: #ffffff;
            margin: 105px;
            margin-bottom: 0px;
            font-family: Poppins-Medium, sans-serif;
            font-size: 18px;
            font-weight: 500;
            line-height: 16px;
            text-transform: capitalize;

            @media (max-width:500px) {
                width: 100%;
                margin: 0;
                margin-top: 50px;
            }
        }

        .book-now-btn-continue:hover {
            width: 55%;
            height: 56px;
            background: #1e5fea;
            background-blend-mode: normal;
            border-radius: 24px;
            color: #ffffff;
            margin: 105px;
            margin-bottom: 0px;
            font-family: Poppins-Medium, sans-serif;
            font-size: 18px;
            font-weight: 500;
            line-height: 16px;
            text-transform: capitalize;

            @media (max-width:500px) {
                width: 100%;
                margin: 0;
                margin-top: 50px;
            }
        }

        h1 {
            font-family: Poppins-Medium, sans-serif;
            font-size: 24px;
            font-weight: 500;
            line-height: 22px;
        }

        .Mui-expanded {
            border: 0px solid #757575 !important;

            #panel1a-header {
                display: none;
            }
        }

        .accordian-main-div {
            width: 100%;
            border-radius: 8px;
            border: 1px solid #757575;
            background: #ffffff;
            background-blend-mode: normal;
            margin-top: 40px;
            margin-bottom: 10px;
            box-shadow: 0px 4px 24px -10px rgba(0, 0, 0, 0.1) !important;

            .MuiCollapse-wrapper {
                overflow: auto;
                height: 211px;
            }

            .MuiCollapse-wrapper::-webkit-scrollbar {
                width: 6px;
                display: initial !important;
            }

            .MuiCollapse-wrapper::-webkit-scrollbar-track {
                -webkit-box-shadow: inset 0 0 6px #e5e5ea;
                border-radius: 39px !important;
            }

            .MuiCollapse-wrapper::-webkit-scrollbar-thumb {
                background-color: #757575;
                border-radius: 39px !important;
            }

            #panel1a-header {
                width: 100%;
                padding-left: 26px;

                p {
                    font-family: Poppins-Medium, sans-serif;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 18px;
                    text-align: right;
                }
            }

            #panel1a-content {
                display: block;
                width: 539px;
                padding-left: 10;
                padding-right: 10;

                @media (max-width:652px) {
                    width: 80.109375vw;
                }

                @media (max-width:460px) {
                    width: 77vw;
                }

                @media (max-width:375px) {
                    width: 70vw;
                }

                hr {
                    border: 0.5px solid #c7c7c763;
                }

                p {
                    color: #2c2c2e;
                    font-family: Poppins-Medium, sans-serif;
                    font-size: 16px;
                    font-weight: 500;
                }
            }
        }

        .hr-tag {
            height: 1px;
            border: 1px dashed #979797;
            background: #999999;
            background-blend-mode: normal;
            margin-top: 20px;
            margin-bottom: 20px;
        }

        div {
            display: flex;
            flex-wrap: wrap;

            h1 {
                color: #2c2c2e;
                font-family: Poppins-Medium, sans-serif;
                font-size: 20px;
                font-weight: 500;
                line-height: 18px;
                width: 80%;
            }

            .p-tag {
                margin-left: auto;
                font-family: Poppins-Medium, sans-serif;
                font-size: 20px;
                font-weight: 500;
                line-height: 18px;
                text-align: right;
                width: 20%;
            }

            .arabic-p-tag{
                margin-left: 0 !important;
                margin-right: auto !important;
            }
        }
    }
    .div-profile-book{
        .h1-tag-profile{
            color: #2c2c2e;
            font-family: Poppins-Medium, sans-serif;
            font-size: 24px;
            font-weight: 500;
            line-height: 30px;
            display: flex;
            align-items: center;
        }
    }
}